import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _size from "lodash/size";
import _sortBy from "lodash/sortBy";

/* eslint-disable */

import Calculator from "./calculator";
import TaxRate from "./tax_rate";

import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelect2 from "../../components/FormSelect2";
import FormMultiSelect from "../../components/FormMultiSelect";
import FormDatePicker from "../../components/FormDatePicker";

import SelectAccounts from "../../components/SelectAccounts";
import SelectContacts from "../../components/SelectContacts";
import SelectPotentials from "../../components/SelectPotentials";

import ListUpdates from "../../components/ListUpdates";
import ListComments from "../../components/ListComments";

import QuoteItems from "./items";
import QuoteTotals from "./totals";

import { InfoButton } from "../../styles/button";
// import { WrapWord } from '../../styles/misc';
import { FormBox } from "../../styles/form";

import { isAdmin } from "../../helpers/auth";
import {
  isArrayExists,
  isObjectExists,
  isNumeric,
} from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import {
  cloneCollections,
  getSelectOptions,
  getSelectValues,
} from "../../helpers/data";
import { reverseUsersValues } from "../../helpers/users";
import { getMomentTime } from "../../helpers/date";
import { isSchemaRequired } from "../../helpers/schemas";
import { getDataFromDoc } from "../../helpers/firebase";

import { quoteSchema } from "../../schemas/quote";
import { COUNTRIES_LIST } from "../../data/const_countries";
import { STATE_OPTIONS } from "../../data/const_states";

import { toggleLoader } from "../../actions/global";

import { PAYMENT_TERMS } from "../../constants";

const useStyles = (theme) => ({
  boxheading: {
    fontSize: "20px",
    fontWeight: "700",
    color: theme.palette.background,
    paddingBottom: "15px",
    marginBottom: "15px",
    borderBottom: "1px solid #ddd",
  },
});

class QuoteDetails extends React.Component {
  state = {
    copyAddress: "accounts",
    copyEmail: "contacts",
    openCalModal: false,
    calModalType: false,
    randNum: false,
  };

  handleFormUpdate = (newValue, key) => {
    const { onFormUpdate, quote, users } = this.props;
    let newData = quote && !_isEmpty(quote) ? cloneCollections(quote) : {};

    switch (key) {
      case "billing_country":
        newData[key] = newValue;
        newData["billing_state"] = "";
        break;
      case "assigned_to":
        newData[key] = reverseUsersValues(newValue, users);
        break;
      case "custom_tax_rate":
        if (isNumeric(newValue) || newValue === "") {
          newData[key] = newValue;
        }
        break;
      default:
        newData[key] = newValue;
        break;
    } // end - key

    // do update
    if (onFormUpdate) onFormUpdate(newData);
  };

  handleCopyAddress = (event) => {
    event.preventDefault();
    const { copyAddress } = this.state;
    const { quote, onFormUpdate } = this.props;

    // make sure copyAddress aren't empty
    if (copyAddress && !_isEmpty(copyAddress)) {
      // toggle loader
      this.props.dispatch(toggleLoader(true));

      var selected_id = false;

      switch (copyAddress) {
        case "accounts":
          if (
            quote &&
            quote.accounts_linked &&
            isArrayExists(quote.accounts_linked)
          ) {
            _forEach(quote.accounts_linked, (account) => {
              selected_id =
                account.id && !_isEmpty(account.id) ? account.id : false;
            });
          } // end - quote.accounts_linked
          break;
        case "contacts":
          if (
            quote &&
            quote.contacts_linked &&
            isArrayExists(quote.contacts_linked)
          ) {
            _forEach(quote.contacts_linked, (contact) => {
              selected_id =
                contact.id && !_isEmpty(contact.id) ? contact.id : false;
            });
          } // end - quote.contacts_linked
          break;
      }

      if (selected_id) {
        getDataFromDoc(copyAddress, selected_id)
          .then((data) => {
            let newData =
              quote && !_isEmpty(quote) ? cloneCollections(quote) : {};

            newData["billing_address"] = data.billing_address || "";
            newData["billing_city"] = data.billing_city || "";
            newData["billing_post_code"] = data.billing_post_code || "";
            newData["billing_state"] = data.billing_state || "";
            newData["billing_country"] = data.billing_country || "";

            // un-toggle loader
            this.props.dispatch(toggleLoader(false));

            // do update
            if (onFormUpdate) onFormUpdate(newData);
          })
          .catch((error) => {
            // un-toggle loader
            this.props.dispatch(toggleLoader(false));

            var errMsg =
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : error.message || "Unable to retrieve billing info";
            triggerErrorAlert(errMsg);
          });
      } else {
        // un-toggle loader
        this.props.dispatch(toggleLoader(false));

        // trigger error
        var errorMessage =
          copyAddress && copyAddress === "accounts"
            ? "No account selected"
            : "No contact selected";
        triggerErrorAlert(errorMessage);
      } // end - selected_id
    } // end - copyAddress
  };

  handleCopyEmail = (event) => {
    event.preventDefault();
    const { copyEmail } = this.state;
    const { quote, onFormUpdate } = this.props;

    // make sure copyEmail aren't empty
    if (copyEmail && !_isEmpty(copyEmail)) {
      // toggle loader
      this.props.dispatch(toggleLoader(true));

      var selected_id = false;

      switch (copyEmail) {
        case "accounts":
          if (
            quote &&
            quote.accounts_linked &&
            isArrayExists(quote.accounts_linked)
          ) {
            _forEach(quote.accounts_linked, (account) => {
              selected_id =
                account.id && !_isEmpty(account.id) ? account.id : false;
            });
          } // end - quote.accounts_linked
          break;
        case "contacts":
          if (
            quote &&
            quote.contacts_linked &&
            isArrayExists(quote.contacts_linked)
          ) {
            _forEach(quote.contacts_linked, (contact) => {
              selected_id =
                contact.id && !_isEmpty(contact.id) ? contact.id : false;
            });
          } // end - quote.contacts_linked
          break;
      }

      if (selected_id) {
        getDataFromDoc(copyEmail, selected_id)
          .then((data) => {
            let newData =
              quote && !_isEmpty(quote) ? cloneCollections(quote) : {};

            newData["email"] = data.email || "";

            // un-toggle loader
            this.props.dispatch(toggleLoader(false));

            // do update
            if (onFormUpdate) onFormUpdate(newData);
          })
          .catch((error) => {
            // un-toggle loader
            this.props.dispatch(toggleLoader(false));

            var errMsg =
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : error.message || "Unable to retrieve billing info";
            triggerErrorAlert(errMsg);
          });
      } else {
        // un-toggle loader
        this.props.dispatch(toggleLoader(false));

        // trigger error
        var errorMessage =
          copyEmail && copyEmail === "accounts"
            ? "No account selected"
            : "No contact selected";
        triggerErrorAlert(errorMessage);
      } // end - selected_id
    } // end - copyEmail
  };

  getFieldOptions = (field) => {
    const { users, quote, selectables, payment_terms, regions } = this.props;
    switch (field.name) {
      case "assigned_to":
        return getSelectOptions({
          list: users ? _filter(users, (u) => u.status !== "disabled") : [],
          keys: { value: "email", label: "name" },
          sortBy: "label",
        });
      case "sales_person":
        var sales_person_options = [{ value: "", label: "Select an Option" }];
        if (quote && quote.assigned_to && isArrayExists(quote.assigned_to)) {
          _forEach(quote.assigned_to, (user) => {
            sales_person_options.push({ value: user.id, label: user.name });
          });
        } // end - quote.assigned_to
        return sales_person_options;
      case "stage":
        return [
          { value: "", label: "Select an Option" },
          { value: "created", label: "Created" },
          { value: "delivered", label: "Delivered" },
          { value: "reviewed", label: "Reviewed" },
          { value: "accepted", label: "Accepted" },
          { value: "rejected", label: "Rejected" },
          { value: "invoiced", label: "Invoiced" },
          { value: "expired", label: "Expired" },
        ];
      case "payment_terms":
        return getSelectOptions({
          list: payment_terms || false,
          options: [],
          keys: { value: "id", label: "label", disabled: "status" },
          sortBy: "label",
        });
      case "company_details":
        return getSelectOptions({
          list:
            regions && isArrayExists(regions)
              ? _sortBy(regions, ["company_name"])
              : [],
          options: [{ value: "", label: "Select an Option" }],
          keys: { value: "id", label: "company_name" },
        });
      case "billing_state":
        let selected =
            quote && quote.billing_country && !_isEmpty(quote.billing_country)
              ? _find(COUNTRIES_LIST, { value: quote.billing_country })
              : false,
          country =
            selected && selected.label && !_isEmpty(selected.label)
              ? _find(STATE_OPTIONS, { country: selected.label })
              : false,
          options = [{ value: "", label: "Select an Option" }];
        if (country && country.states && isArrayExists(country.states)) {
          country.states.forEach((state) => {
            options.push({ value: state, label: state });
          });
        } // end - country.states
        return options;
      case "billing_country":
        return getSelectOptions({
          list: COUNTRIES_LIST,
          options: [{ value: "", label: "Select an Option" }],
          keys: { value: "value", label: "label" },
        });
      case "tax_mode":
        return [
          { value: "none", label: "None" },
          { value: "individual", label: "Individual" },
          { value: "group", label: "Group" },
        ];
      case "currency":
        let currency =
            selectables && isArrayExists(selectables)
              ? _find(selectables, { id: "currency" })
              : false,
          currency_options = [{ value: "", label: "Select an Option" }];
        if (currency && currency.options && isArrayExists(currency.options)) {
          _forEach(_sortBy(currency.options, ["position"]), (option) => {
            currency_options.push({
              value: option.value,
              label: option.label + " (" + option.value + ")",
            });
          });
        } // end - currency
        return currency_options;
      default:
        return [];
    }
  };

  getFieldValueObj = (field) => {
    const { quote, isAddNew } = this.props;
    var val = "";
    if (!isAddNew) {
      switch (field.name) {
        case "accounts_linked":
        case "contacts_linked":
        case "potentials_linked":
          // turn array to string
          if (quote && quote[field.name] && isArrayExists(quote[field.name])) {
            quote[field.name].forEach((item) => {
              val = cloneCollections(item);
            });
          } // end - quote[field.name]
          break;
      }
    }
    return val;
  };

  getFieldValue = (field) => {
    const { quote, isAddNew, authData } = this.props;
    switch (field.name) {
      case "accounts_linked":
      case "contacts_linked":
      case "potentials_linked":
        let val = "";
        // turn array to string
        if (quote && quote[field.name] && isArrayExists(quote[field.name])) {
          quote[field.name].forEach((item) => {
            val = item.id || false;
          });
        } // end - quote[field.name]
        return val;
      case "issue_date":
        if (isAddNew)
          return quote && quote[field.name]
            ? quote[field.name]
            : moment().utcOffset(8).startOf("date").valueOf();
        else return quote && quote[field.name] ? quote[field.name] : null;
      case "company_details":
        if (isAddNew)
          return quote && quote[field.name]
            ? quote[field.name]
            : authData && authData.region && !_isEmpty(authData.region)
            ? authData.region
            : "";
        else return quote && quote[field.name] ? quote[field.name] : "";
      case "assigned_to":
        return quote && quote[field.name] && isArrayExists(quote[field.name])
          ? getSelectValues(quote[field.name], "id")
          : [];
      default:
        return (quote && quote[field.name]) || field.default || "";
    }
  };

  isFieldDisabled = (schema) => {
    const { authData, quote, contacts } = this.props;
    var disabled = false;

    if (schema && schema.disabled && isArrayExists(schema.disabled)) {
      schema.disabled.forEach((condition) => {
        switch (condition) {
          case "admin":
            if (!isAdmin(authData)) disabled = true;
            break;
          case "update":
            disabled = true;
            break;
        }
      });
    } // end - schema

    return disabled;
  };

  getField = (id) => {
    const { isAddNew } = this.props;
    let schema = _find(quoteSchema, { id });
    return schema
      ? {
          name: schema.id || "",
          label:
            (schema.label || "") +
            (isSchemaRequired(schema, isAddNew ? "add" : "update")
              ? " (Required)"
              : ""),
          field_type: schema.field || "",
          default: schema.default || null,
          disabled: schema.disabled ? this.isFieldDisabled(schema) : false,
        }
      : null;
  };

  renderField = (id) => {
    const { quote, authData } = this.props;
    let field = this.getField(id);
    if (field && field.field_type && !_isEmpty(field.field_type)) {
      switch (field.field_type) {
        case "text":
          return (
            <FormInput
              {...field}
              value={this.getFieldValue(field)}
              onChange={this.handleFormUpdate}
            />
          );
        case "textarea":
          return (
            <FormInput
              {...field}
              rows={"notes" === id ? 6 : 3}
              multiline={true}
              value={this.getFieldValue(field)}
              onChange={this.handleFormUpdate}
            />
          );
        case "text_number":
          return (
            <FormInput
              {...field}
              type="number"
              value={this.getFieldValue(field)}
              onChange={this.handleFormUpdate}
            />
          );
        case "email":
          return (
            <FormInput
              {...field}
              type="email"
              value={this.getFieldValue(field)}
              onChange={this.handleFormUpdate}
            />
          );
        case "select2":
          if (id === "potentials_linked") {
            return (
              <SelectPotentials
                {...field}
                value={this.getFieldValue(field)}
                valueObj={this.getFieldValueObj(field)}
                isClearable={true}
                onChange={(newPotentials, key) => {
                  const { quote, onFormUpdate } = this.props;
                  var newData = quote ? cloneCollections(quote) : {};
                  if (
                    newPotentials &&
                    newPotentials[0] &&
                    newPotentials[0].id &&
                    !_isEmpty(newPotentials[0].id)
                  ) {
                    newData[key] = [
                      {
                        id: newPotentials[0].id,
                        name: newPotentials[0].name || "",
                      },
                    ];

                    // add accounts_linked if available
                    newData["accounts_linked"] = [];
                    if (
                      newPotentials[0].accounts_linked &&
                      !_isEmpty(newPotentials[0].accounts_linked)
                    ) {
                      _forEach(newPotentials[0].accounts_linked, (acct) => {
                        newData["accounts_linked"].push(acct);
                      });
                    } // end - newPotentials[0].accounts_linked

                    // add contacts_linked if available
                    newData["contacts_linked"] = [];
                    if (
                      newPotentials[0].contacts_linked &&
                      !_isEmpty(newPotentials[0].contacts_linked)
                    ) {
                      _forEach(newPotentials[0].contacts_linked, (cont) => {
                        if (cont.id && !_isEmpty(cont.id)) {
                          newData["contacts_linked"].push({
                            id: cont.id,
                            name: cont.name || "",
                            email: cont.email || "",
                            account_id: cont.account_id || "",
                          });
                        } // end - cont.id
                      });
                    } // end - newPotentials[0].contacts_linked

                    // update assigned_to
                    if (
                      newPotentials &&
                      newPotentials[0] &&
                      newPotentials[0].assigned_to &&
                      isObjectExists(newPotentials[0].assigned_to)
                    ) {
                      newData["assigned_to"] = [];
                      _forEach(newPotentials[0].assigned_to, (user) => {
                        newData["assigned_to"].push(user);
                      });
                    } else {
                      // reset assigned_to back to logged-in user
                      newData["assigned_to"] =
                        authData && authData.name && authData.email
                          ? [{ id: authData.email, name: authData.name }]
                          : [];
                    } // end - newPotentials[0].assigned_to
                  } else {
                    // reset
                    newData[key] = [];
                    newData["accounts_linked"] = [];
                    newData["contacts_linked"] = [];

                    // reset assigned_to back to logged-in user
                    newData["assigned_to"] =
                      authData && authData.name && authData.email
                        ? [{ id: authData.email, name: authData.name }]
                        : [];
                  } // end - newPotentials

                  // do update
                  if (onFormUpdate) onFormUpdate(newData);
                }}
              />
            );
          } else if (id === "accounts_linked") {
            return (
              <SelectAccounts
                {...field}
                value={this.getFieldValue(field)}
                valueObj={this.getFieldValueObj(field)}
                onChange={(newAccounts, key) => {
                  const { quote, onFormUpdate } = this.props;
                  var newData = quote ? cloneCollections(quote) : {};
                  if (newAccounts && newAccounts[0] && newAccounts[0].id) {
                    // update account
                    newData[key] = newAccounts;

                    // update assigned_to
                    if (
                      newAccounts &&
                      newAccounts[0] &&
                      newAccounts[0].assigned_to &&
                      isObjectExists(newAccounts[0].assigned_to)
                    ) {
                      newData["assigned_to"] = [];
                      _forEach(newAccounts[0].assigned_to, (user) => {
                        newData["assigned_to"].push(user);
                      });
                    } // end - newAccounts[0].assigned_to
                  } else {
                    // reset
                    newData[key] = [];
                    newData["contacts_linked"] = [];

                    // reset assigned_to back to logged in user
                    newData["assigned_to"] =
                      authData && authData.name && authData.email
                        ? [{ id: authData.email, name: authData.name }]
                        : [];
                  } // end - account

                  // do update
                  if (onFormUpdate) onFormUpdate(newData);
                }}
              />
            );
          } else if (id === "contacts_linked") {
            return (
              <SelectContacts
                {...field}
                value={this.getFieldValue(field)}
                valueObj={this.getFieldValueObj(field)}
                selected_accounts={
                  quote &&
                  quote.accounts_linked &&
                  isArrayExists(quote.accounts_linked)
                    ? quote.accounts_linked
                    : []
                }
                onChange={(newContacts, key) => {
                  const { quote, onFormUpdate } = this.props;
                  var newData = quote ? cloneCollections(quote) : {};
                  newData[key] =
                    newContacts &&
                    newContacts[0] &&
                    newContacts[0].id &&
                    !_isEmpty(newContacts[0].id)
                      ? [
                          {
                            id: newContacts[0].id,
                            name: newContacts[0].name || "",
                            email: newContacts[0].email || "",
                            account_id: newContacts[0].account_id || "",
                          },
                        ]
                      : [];

                  // do update
                  if (onFormUpdate) onFormUpdate(newData);
                }}
              />
            );
          } else {
            return (
              <FormSelect2
                placeholder="Select an Option"
                {...field}
                value={this.getFieldValue(field)}
                options={this.getFieldOptions(field)}
                onChange={this.handleFormUpdate}
              />
            );
          } // end - id
        case "select":
          return (
            <div style={{ paddingTop: "7px" }}>
              <FormSelect
                {...field}
                value={this.getFieldValue(field)}
                options={this.getFieldOptions(field)}
                disableNative={id === "payment_terms" ? true : false}
                onChange={this.handleFormUpdate}
              />
            </div>
          );
        case "multiselect":
          return (
            <div style={{ paddingTop: "7px" }}>
              <FormMultiSelect
                {...field}
                value={this.getFieldValue(field)}
                options={this.getFieldOptions(field)}
                onChange={this.handleFormUpdate}
              />
            </div>
          );
        case "datepicker":
          return (
            <FormDatePicker
              {...field}
              noDefaultVal={true}
              value={this.getFieldValue(field)}
              onChange={this.handleFormUpdate}
            />
          );
      }
    } // end - field.field_type
  };

  renderUpdatesBox = () => {
    const { classes, quote } = this.props;
    return (
      <div>
        <Typography variant="h4" className={classes.boxheading}>
          Updates
        </Typography>
        <ListUpdates
          list={
            quote && quote.updates && isArrayExists(quote.updates)
              ? quote.updates
              : false
          }
        />
      </div>
    );
  };

  renderCommentsBox = () => {
    const { classes, quote, authData, updateActions } = this.props;
    return (
      <div>
        <Typography variant="h4" className={classes.boxheading}>
          Comments
        </Typography>
        <ListComments
          authData={authData || false}
          refer_type="quote"
          refer_id={quote && quote.id && !_isEmpty(quote.id) ? quote.id : false}
          refer_label={
            quote && quote.name && !_isEmpty(quote.name) ? quote.name : false
          }
          updateActions={updateActions || false}
          list={
            quote && quote.comments && isArrayExists(quote.comments)
              ? quote.comments
              : false
          }
        />
      </div>
    );
  };

  renderCompanyDetails = () => {
    const { classes, quote } = this.props;
    return (
      <FormBox style={{ paddingBottom: "45px" }}>
        <Typography variant="h4" className={classes.boxheading}>
          Entity
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {this.renderField("company_details")}
          </Grid>
        </Grid>
      </FormBox>
    );
  };

  renderAddressDetails = () => {
    const { classes, quote } = this.props;
    const { copyAddress } = this.state;
    return (
      <FormBox style={{ paddingBottom: "45px" }}>
        <Typography variant="h4" className={classes.boxheading}>
          Address Details
        </Typography>

        <div style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start" spacing={0}>
            <div style={{ width: "500px" }}>
              <FormSelect
                label="Copy Billing Info From"
                value={copyAddress || ""}
                options={[
                  { value: "accounts", label: "Account" },
                  { value: "contacts", label: "Contact" },
                ]}
                onChange={(newValue) =>
                  this.setState({ copyAddress: newValue })
                }
              />
            </div>
            <InfoButton
              disabled={copyAddress && !_isEmpty(copyAddress) ? false : true}
              style={{ marginLeft: "5px" }}
              onClick={this.handleCopyAddress}
            >
              <i className="fa fa-clone"></i>Copy
            </InfoButton>
          </Grid>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {this.renderField("billing_address")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("billing_city")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("billing_post_code")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("billing_state")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("billing_country")}
          </Grid>
        </Grid>
      </FormBox>
    );
  };

  renderQuoteItems = () => {
    const { classes, quote, regions, selectables, authData } = this.props;
    return (
      <FormBox style={{ paddingBottom: "0px" }}>
        <Typography variant="h4" className={classes.boxheading}>
          Item Details
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {this.renderField("currency")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("tax_mode")}
          </Grid>
          {quote &&
          quote.currency &&
          !_isEmpty(quote.currency) &&
          quote.currency !== "RM" ? (
            <Grid item xs={6}>
              {this.renderField("currency_conversion_rate")}
            </Grid>
          ) : null}
          {quote.tax_mode &&
            (quote.tax_mode === "individual" || quote.tax_mode === "group") && (
              <Grid item xs={6}>
                {/* {this.renderField('custom_tax_rate')} */}
                <TaxRate
                  authData={authData || false}
                  item={quote || false}
                  regions={regions || []}
                  onUpdate={(newValue) => {
                    this.handleFormUpdate(newValue, "custom_tax_rate");
                  }}
                />
              </Grid>
            )}
        </Grid>

        <QuoteItems
          authData={authData}
          quote={quote || false}
          regions={regions || false}
          selectables={selectables || false}
          onFormUpdate={(newData) => {
            const { onFormUpdate } = this.props;
            // do update
            if (onFormUpdate) onFormUpdate(newData);
          }}
          onCalTriggered={(product_id) =>
            this.setState({ openCalModal: true, calModalType: product_id })
          }
        />
      </FormBox>
    );
  };

  renderQuoteTotals = () => {
    const { quote, regions, authData, isAddNew } = this.props;
    return (
      <FormBox style={{ paddingBottom: "45px" }}>
        <QuoteTotals
          isAddNew={isAddNew || false}
          authData={authData}
          quote={quote || false}
          regions={regions || false}
          onFormUpdate={(newData) => {
            const { onFormUpdate } = this.props;
            // do update
            if (onFormUpdate) onFormUpdate(newData);
          }}
        />
      </FormBox>
    );
  };

  renderCopyEmailField = () => {
    const { copyEmail } = this.state;
    return (
      <div style={{ marginBottom: "0px" }}>
        <Grid container justify="flex-start" spacing={0}>
          <div style={{ width: "400px" }}>
            <FormSelect
              label="Copy Email From"
              value={copyEmail || ""}
              options={[
                // { value: '', label: 'None' },
                { value: "accounts", label: "Account" },
                { value: "contacts", label: "Contact" },
              ]}
              onChange={(newValue) => this.setState({ copyEmail: newValue })}
            />
          </div>
          <InfoButton
            disabled={copyEmail && !_isEmpty(copyEmail) ? false : true}
            style={{ marginLeft: "5px" }}
            onClick={this.handleCopyEmail}
          >
            <i className="fa fa-clone"></i>Copy
          </InfoButton>
        </Grid>
      </div>
    );
  };

  renderDetailsBox = () => {
    const { classes, quote, isAddNew } = this.props;
    return (
      <FormBox style={{ paddingBottom: "30px" }}>
        <Typography variant="h4" className={classes.boxheading}>
          Quote Info
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {this.renderField("name")}
          </Grid>

          <Grid item xs={6}>
            {isAddNew ? null : this.renderField("id")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("stage")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("reference")}
          </Grid>

          <Grid item xs={6}>
            {this.renderField("potentials_linked")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("accounts_linked")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("contacts_linked")}
          </Grid>
          <Grid item xs={6}>
            {" "}
          </Grid>

          <Grid item xs={12}>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
          </Grid>

          <Grid item xs={6}>
            {this.renderField("email")}
          </Grid>
          <Grid item xs={6}>
            {this.renderCopyEmailField()}
          </Grid>

          <Grid item xs={6}>
            {this.renderField("payment_terms")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("issue_date")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("sales_person")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("valid_until")}
          </Grid>

          <Grid item xs={12}>
            {this.renderField("notes")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("assigned_to")}
          </Grid>
        </Grid>

        <div
          style={{
            paddingTop: "10px",
            textAlign: "right",
            color: "#999",
            fontSize: "1.25rem",
          }}
        >
          <div>
            {quote.created_on
              ? "Created on " +
                getMomentTime(quote.created_on, "YYYY-MM-DD hh:mm:ssa")
              : ""}
          </div>
          <div>
            {quote.modified_on
              ? "Last Modified on " +
                getMomentTime(quote.modified_on, "YYYY-MM-DD hh:mm:ssa")
              : ""}
          </div>
        </div>
      </FormBox>
    );
  };

  render() {
    const { isAddNew, authData, regions, quote } = this.props;
    const { openCalModal, calModalType } = this.state;
    return (
      <div>
        {this.renderCompanyDetails()}
        {this.renderDetailsBox()}
        {this.renderAddressDetails()}
        {this.renderQuoteItems()}
        {this.renderQuoteTotals()}

        {isAddNew ? null : (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {this.renderCommentsBox()}
            </Grid>
            <Grid item xs={6}>
              {this.renderUpdatesBox()}
            </Grid>
          </Grid>
        )}

        <Calculator
          authData={authData || false}
          open={openCalModal}
          type={calModalType}
          item={quote || false}
          regions={regions || []}
          onUpdate={(newValue, key) => {
            this.handleFormUpdate(newValue, key);
            this.setState({ openCalModal: false, calModalType: false });
          }}
          onClose={() =>
            this.setState({ openCalModal: false, calModalType: false })
          }
        />
      </div>
    );
  }
}

export default compose(
  connect(),
  withStyles(useStyles),
  withRouter
)(QuoteDetails);
