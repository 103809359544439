import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _size from "lodash/size";
import _sortBy from "lodash/sortBy";

/* eslint-disable */

import Calculator from "../QuotePage/calculator";
import TaxRate from "../QuotePage/tax_rate";

import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelect2 from "../../components/FormSelect2";
import FormMultiSelect from "../../components/FormMultiSelect";
import FormDatePicker from "../../components/FormDatePicker";

import SelectAccounts from "../../components/SelectAccounts";
import SelectContacts from "../../components/SelectContacts";
import SelectPotentials from "../../components/SelectPotentials";

import ListUpdates from "../../components/ListUpdates";
import ListComments from "../../components/ListComments";

import InvoiceItems from "./items";
import InvoiceTotals from "./totals";

import { InfoButton } from "../../styles/button";
// import { WrapWord } from '../../styles/misc';
import { FormBox } from "../../styles/form";

import { isAdmin } from "../../helpers/auth";
import {
  isArrayExists,
  isObjectExists,
  isNumeric,
} from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import {
  cloneCollections,
  getSelectOptions,
  getSelectValues,
} from "../../helpers/data";
import { reverseUsersValues } from "../../helpers/users";
import { getMomentTime } from "../../helpers/date";
import { isSchemaRequired } from "../../helpers/schemas";
import { getDataFromDoc } from "../../helpers/firebase";

import { invoiceSchema } from "../../schemas/invoice";
import { COUNTRIES_LIST } from "../../data/const_countries";
import { STATE_OPTIONS } from "../../data/const_states";

import { toggleLoader } from "../../actions/global";

import { PAYMENT_TERMS } from "../../constants";

const useStyles = (theme) => ({
  boxheading: {
    fontSize: "20px",
    fontWeight: "700",
    color: theme.palette.background,
    paddingBottom: "15px",
    marginBottom: "15px",
    borderBottom: "1px solid #ddd",
  },
});

class InvoiceDetails extends React.Component {
  state = {
    copyAddress: "accounts",
    copyEmail: "contacts",
    openCalModal: false,
    calModalType: false,
    randNum: false,
  };

  handleFormUpdate = (newValue, key) => {
    const { onFormUpdate, invoice, users } = this.props;
    let newData =
      invoice && !_isEmpty(invoice) ? cloneCollections(invoice) : {};

    switch (key) {
      case "billing_country":
        newData[key] = newValue;
        newData["billing_state"] = "";
        break;
      case "assigned_to":
        newData[key] = reverseUsersValues(newValue, users);
        break;
      case "custom_tax_rate":
        if (isNumeric(newValue) || newValue === "") {
          newData[key] = newValue;
        }
        break;
      default:
        newData[key] = newValue;
        break;
    } // end - key

    // do update
    if (onFormUpdate) onFormUpdate(newData);
  };

  handleCopyAddress = (event) => {
    event.preventDefault();
    const { copyAddress } = this.state;
    const { invoice, onFormUpdate } = this.props;

    // make sure copyAddress aren't empty
    if (copyAddress && !_isEmpty(copyAddress)) {
      // toggle loader
      this.props.dispatch(toggleLoader(true));

      var selected_id = false;

      switch (copyAddress) {
        case "accounts":
          if (
            invoice &&
            invoice.accounts_linked &&
            isArrayExists(invoice.accounts_linked)
          ) {
            _forEach(invoice.accounts_linked, (account) => {
              selected_id =
                account.id && !_isEmpty(account.id) ? account.id : false;
            });
          } // end - invoice.accounts_linked
          break;
        case "contacts":
          if (
            invoice &&
            invoice.contacts_linked &&
            isArrayExists(invoice.contacts_linked)
          ) {
            _forEach(invoice.contacts_linked, (contact) => {
              selected_id =
                contact.id && !_isEmpty(contact.id) ? contact.id : false;
            });
          } // end - invoice.contacts_linked
          break;
      }

      if (selected_id) {
        getDataFromDoc(copyAddress, selected_id)
          .then((data) => {
            let newData =
              invoice && !_isEmpty(invoice) ? cloneCollections(invoice) : {};

            newData["billing_address"] = data.billing_address || "";
            newData["billing_city"] = data.billing_city || "";
            newData["billing_post_code"] = data.billing_post_code || "";
            newData["billing_state"] = data.billing_state || "";
            newData["billing_country"] = data.billing_country || "";

            // un-toggle loader
            this.props.dispatch(toggleLoader(false));

            // do update
            if (onFormUpdate) onFormUpdate(newData);
          })
          .catch((error) => {
            // un-toggle loader
            this.props.dispatch(toggleLoader(false));

            var errMsg =
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : error.message || "Unable to retrieve billing info";
            triggerErrorAlert(errMsg);
          });
      } else {
        // un-toggle loader
        this.props.dispatch(toggleLoader(false));

        // trigger error
        var errorMessage =
          copyAddress && copyAddress === "accounts"
            ? "No account selected"
            : "No contact selected";
        triggerErrorAlert(errorMessage);
      } // end - selected_id
    } // end - copyAddress
  };

  handleCopyEmail = (event) => {
    event.preventDefault();
    const { copyEmail } = this.state;
    const { invoice, onFormUpdate } = this.props;

    // make sure copyEmail aren't empty
    if (copyEmail && !_isEmpty(copyEmail)) {
      // toggle loader
      this.props.dispatch(toggleLoader(true));

      var selected_id = false,
        mod = false;

      switch (copyEmail) {
        case "accounts":
          if (
            invoice &&
            invoice.accounts_linked &&
            isArrayExists(invoice.accounts_linked)
          ) {
            _forEach(invoice.accounts_linked, (account) => {
              selected_id =
                account.id && !_isEmpty(account.id) ? account.id : false;
              mod = "accounts";
            });
          } // end - invoice.accounts_linked
          break;
        case "end_user_company":
          if (
            invoice &&
            invoice.end_user_company_linked &&
            isArrayExists(invoice.end_user_company_linked)
          ) {
            _forEach(invoice.end_user_company_linked, (account) => {
              selected_id =
                account.id && !_isEmpty(account.id) ? account.id : false;
              mod = "accounts";
            });
          } // end - invoice.end_user_company_linked
          break;
        case "contacts":
          if (
            invoice &&
            invoice.contacts_linked &&
            isArrayExists(invoice.contacts_linked)
          ) {
            _forEach(invoice.contacts_linked, (contact) => {
              selected_id =
                contact.id && !_isEmpty(contact.id) ? contact.id : false;
              mod = "contacts";
            });
          } // end - invoice.contacts_linked
          break;
      }

      if (selected_id && mod && !_isEmpty(mod)) {
        getDataFromDoc(mod, selected_id)
          .then((data) => {
            let newData =
              invoice && !_isEmpty(invoice) ? cloneCollections(invoice) : {};

            newData["email"] = data.email || "";

            // un-toggle loader
            this.props.dispatch(toggleLoader(false));

            // do update
            if (onFormUpdate) onFormUpdate(newData);
          })
          .catch((error) => {
            // un-toggle loader
            this.props.dispatch(toggleLoader(false));

            var errMsg =
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : error.message || "Unable to retrieve billing info";
            triggerErrorAlert(errMsg);
          });
      } else {
        // un-toggle loader
        this.props.dispatch(toggleLoader(false));

        // trigger error
        if (copyEmail && copyEmail === "accounts") {
          var errorMessage = "No account selected";
        } else if (copyEmail && copyEmail === "end_user_company") {
          var errorMessage = "No End User Company selected";
        } else {
          var errorMessage = "No contact selected";
        }
        triggerErrorAlert(errorMessage);
      } // end - selected_id
    } // end - copyEmail
  };

  getFieldOptions = (field) => {
    const {
      users,
      invoice,
      selectables,
      regions,
      course_portfolio,
      invoice_type,
      payment_terms,
    } = this.props;
    const companyDetails = this.getFieldValue({ name: "company_details" });
    switch (field.name) {
      case "assigned_to":
        return getSelectOptions({
          list: users ? _filter(users, (u) => u.status !== "disabled") : [],
          keys: { value: "email", label: "name" },
          sortBy: "label",
        });
      case "issued_by":
        var currentVal = this.getFieldValue(field),
          fieldOptions = getSelectOptions({
            list: users ? _filter(users, (u) => u.status !== "disabled") : [],
            keys: { value: "email", label: "name" },
            sortBy: "label",
          });
        // if current value wasn't in the fieldOptions
        if (
          currentVal &&
          !_isEmpty(currentVal) &&
          !_find(fieldOptions, { value: currentVal })
        ) {
          // check if is disabled user
          let disabled_user = _find(users, { email: currentVal });
          if (disabled_user) {
            fieldOptions.push({
              value: currentVal,
              label: disabled_user.name || currentVal,
            });
          } else {
            fieldOptions.push({ value: currentVal, label: currentVal });
          } // end - disabled_user
        } // end - currentVal
        return fieldOptions;
      case "sales_person":
        var currentVal = this.getFieldValue(field),
          sales_person_options = [{ value: "", label: "Select an Option" }];
        if (
          invoice &&
          invoice.assigned_to &&
          isArrayExists(invoice.assigned_to)
        ) {
          _forEach(invoice.assigned_to, (user) => {
            sales_person_options.push({ value: user.id, label: user.name });
          });
        } // end - invoice.assigned_to
        // if current value wasn't in the fieldOptions
        if (
          currentVal &&
          !_isEmpty(currentVal) &&
          !_find(sales_person_options, { value: currentVal })
        ) {
          var selected_user =
            users && !_isEmpty(users)
              ? _find(users, { email: currentVal })
              : false;
          sales_person_options.push({
            value: currentVal,
            label:
              selected_user &&
              selected_user.name &&
              !_isEmpty(selected_user.name)
                ? selected_user.name
                : currentVal,
          });
        }
        return sales_person_options;
      case "status":
        return [
          { value: "", label: "Select an Option" },
          { value: "created", label: "Created" },
          { value: "sent", label: "Sent" },
          { value: "paid", label: "Paid" },
          { value: "approved", label: "Approved" },
          { value: "cancel", label: "Cancel" },
          { value: "auto-created", label: "Auto Created" },
        ];
      case "business":
        const regionBasedCoursePortfolio =
          companyDetails && !_isEmpty(companyDetails)
            ? invoice_type && isArrayExists(course_portfolio)
              ? _filter(course_portfolio, (i) => {
                  return i.regions &&
                    isArrayExists(i.regions) &&
                    companyDetails &&
                    _find(i.regions, { id: companyDetails })
                    ? true
                    : false;
                })
              : []
            : [
                {
                  value: "",
                  label: "Please select company first",
                  status: "disabled",
                },
              ];
        return getSelectOptions({
          list:
            regionBasedCoursePortfolio &&
            isArrayExists(regionBasedCoursePortfolio)
              ? _sortBy(regionBasedCoursePortfolio, ["label"])
              : [],
          options: [],
          keys: { value: "id", label: "label", disabled: "status" },
        });
      case "invoice_type":
        const regionBasedInvoiceType =
          companyDetails && !_isEmpty(companyDetails)
            ? invoice_type && isArrayExists(invoice_type)
              ? _filter(invoice_type, (i) => {
                  return i.regions &&
                    isArrayExists(i.regions) &&
                    companyDetails &&
                    _find(i.regions, { id: companyDetails })
                    ? true
                    : false;
                })
              : []
            : [
                {
                  value: "",
                  label: "Please select company first",
                  status: "disabled",
                },
              ];
        return getSelectOptions({
          list: regionBasedInvoiceType,
          options: [],
          keys: { value: "id", label: "label", disabled: "status" },
        });
      case "payment_terms":
        return getSelectOptions({
          list: payment_terms || false,
          options: [],
          keys: { value: "id", label: "label", disabled: "status" },
          sortBy: "label",
        });
      case "company_details":
        return getSelectOptions({
          list:
            regions && isArrayExists(regions)
              ? _sortBy(regions, ["company_name"])
              : [],
          options: [{ value: "", label: "Select an Option" }],
          keys: { value: "id", label: "company_name" },
        });
      case "billing_state":
        let selected =
            invoice &&
            invoice.billing_country &&
            !_isEmpty(invoice.billing_country)
              ? _find(COUNTRIES_LIST, { value: invoice.billing_country })
              : false,
          country =
            selected && selected.label && !_isEmpty(selected.label)
              ? _find(STATE_OPTIONS, { country: selected.label })
              : false,
          options = [{ value: "", label: "Select an Option" }];
        if (country && country.states && isArrayExists(country.states)) {
          country.states.forEach((state) => {
            options.push({ value: state, label: state });
          });
        } // end - country.states
        return options;
      case "billing_country":
        return getSelectOptions({
          list: COUNTRIES_LIST,
          options: [{ value: "", label: "Select an Option" }],
          keys: { value: "value", label: "label" },
        });
      case "tax_mode":
        return [
          { value: "none", label: "None" },
          { value: "individual", label: "Individual" },
          { value: "group", label: "Group" },
        ];
      case "currency":
        let currency =
            selectables && isArrayExists(selectables)
              ? _find(selectables, { id: "currency" })
              : false,
          currency_options = [{ value: "", label: "Select an Option" }];
        if (currency && currency.options && isArrayExists(currency.options)) {
          _forEach(_sortBy(currency.options, ["position"]), (option) => {
            currency_options.push({
              value: option.value,
              label: option.label + " (" + option.value + ")",
            });
          });
        } // end - currency
        return currency_options;
      default:
        return [];
    }
  };

  getFieldValueObj = (field) => {
    const { invoice, isAddNew } = this.props;
    var val = "";
    if (!isAddNew) {
      switch (field.name) {
        case "accounts_linked":
        case "contacts_linked":
        case "end_user_company_linked":
          // turn array to string
          if (
            invoice &&
            invoice[field.name] &&
            isArrayExists(invoice[field.name])
          ) {
            invoice[field.name].forEach((item) => {
              val = cloneCollections(item);
            });
          } // end - invoice[field.name]
          break;
      }
    }
    return val;
  };

  getFieldValue = (field) => {
    const { invoice, authData, isAddNew } = this.props;
    switch (field.name) {
      case "accounts_linked":
      case "contacts_linked":
        var val = "";
        // turn array to string
        if (
          invoice &&
          invoice[field.name] &&
          isArrayExists(invoice[field.name])
        ) {
          invoice[field.name].forEach((item) => {
            val = item.id || false;
          });
        } // end - invoice[field.name]
        return val;
      case "end_user_company_linked":
        var val = "";
        // turn array to string
        if (
          invoice &&
          invoice[field.name] &&
          isArrayExists(invoice[field.name])
        ) {
          invoice[field.name].forEach((item) => {
            val = item.id || false;
          });
        } else {
          // if end_user_company_linked is empty, look for accounts_linked
          // if ( invoice && invoice.accounts_linked && isArrayExists( invoice.accounts_linked ) ) {
          //     invoice.accounts_linked.forEach(item => {
          //         val = ( item.id || false );
          //     });
          // } // end - invoice.accounts_linked
        } // end - invoice[field.name]
        return val;
      case "assigned_to":
        return invoice &&
          invoice[field.name] &&
          isArrayExists(invoice[field.name])
          ? getSelectValues(invoice[field.name], "id")
          : [];
      case "issued_by":
        return invoice && invoice[field.name]
          ? invoice[field.name]
          : (authData && authData.email) || "";
      case "invoice_date":
        return invoice && invoice[field.name]
          ? invoice[field.name]
          : moment().utcOffset(8).startOf("date").valueOf();
      case "company_details":
        if (isAddNew)
          return invoice && invoice[field.name]
            ? invoice[field.name]
            : authData && authData.region && !_isEmpty(authData.region)
            ? authData.region
            : "";
        else return invoice && invoice[field.name] ? invoice[field.name] : "";
      default:
        return (invoice && invoice[field.name]) || field.default || "";
    }
  };

  isFieldDisabled = (schema) => {
    const { authData, invoice, contacts } = this.props;
    var disabled = false;

    if (schema && schema.disabled && isArrayExists(schema.disabled)) {
      schema.disabled.forEach((condition) => {
        switch (condition) {
          case "admin":
            if (!isAdmin(authData)) disabled = true;
            break;
          case "update":
            disabled = true;
            break;
        }
      });
    } // end - schema

    return disabled;
  };

  getField = (id) => {
    const { isAddNew } = this.props;
    let schema = _find(invoiceSchema, { id });
    return schema
      ? {
          name: schema.id || "",
          label:
            (schema.label || "") +
            (isSchemaRequired(schema, isAddNew ? "add" : "update")
              ? " (Required)"
              : ""),
          field_type: schema.field || "",
          default: schema.default || null,
          disabled: schema.disabled ? this.isFieldDisabled(schema) : false,
        }
      : null;
  };

  renderField = (id) => {
    const { invoice, authData } = this.props;
    let field = this.getField(id);
    if (field && field.field_type && !_isEmpty(field.field_type)) {
      switch (field.field_type) {
        case "text":
          return (
            <FormInput
              {...field}
              value={this.getFieldValue(field)}
              onChange={this.handleFormUpdate}
            />
          );
        case "textarea":
          return (
            <FormInput
              {...field}
              rows={"notes" === id ? 6 : 3}
              multiline={true}
              value={this.getFieldValue(field)}
              onChange={this.handleFormUpdate}
            />
          );
        case "text_number":
          return (
            <FormInput
              {...field}
              type="number"
              value={this.getFieldValue(field)}
              onChange={this.handleFormUpdate}
            />
          );
        case "email":
          return (
            <FormInput
              {...field}
              type="email"
              value={this.getFieldValue(field)}
              onChange={this.handleFormUpdate}
            />
          );
        case "select2":
          if (id === "accounts_linked") {
            return (
              <SelectAccounts
                {...field}
                value={this.getFieldValue(field)}
                valueObj={this.getFieldValueObj(field)}
                onChange={(newAccounts, key) => {
                  const { invoice, onFormUpdate } = this.props;
                  var newData = invoice ? cloneCollections(invoice) : {};
                  if (newAccounts && newAccounts[0] && newAccounts[0].id) {
                    // update account
                    newData[key] = newAccounts;
                  } else {
                    // reset
                    newData[key] = [];
                  } // end - account

                  // do update
                  if (onFormUpdate) onFormUpdate(newData);
                }}
              />
            );
          } else if (id === "end_user_company_linked") {
            return (
              <SelectAccounts
                {...field}
                value={this.getFieldValue(field)}
                valueObj={this.getFieldValueObj(field)}
                onChange={(newAccounts, key) => {
                  const { invoice, onFormUpdate } = this.props;
                  var newData = invoice ? cloneCollections(invoice) : {};
                  if (newAccounts && newAccounts[0] && newAccounts[0].id) {
                    // update account
                    newData[key] = newAccounts;

                    // update assigned_to
                    if (
                      newAccounts &&
                      newAccounts[0] &&
                      newAccounts[0].assigned_to &&
                      isObjectExists(newAccounts[0].assigned_to)
                    ) {
                      newData["assigned_to"] = [];
                      _forEach(newAccounts[0].assigned_to, (user) => {
                        newData["assigned_to"].push(user);
                      });
                    } // end - newAccounts[0].assigned_to
                  } else {
                    // reset
                    newData[key] = [];
                    newData["contacts_linked"] = [];

                    // reset assigned_to back to logged in user
                    newData["assigned_to"] =
                      authData && authData.name && authData.email
                        ? [{ id: authData.email, name: authData.name }]
                        : [];
                  } // end - account

                  // do update
                  if (onFormUpdate) onFormUpdate(newData);
                }}
              />
            );
          } else if (id === "contacts_linked") {
            return (
              <SelectContacts
                {...field}
                value={this.getFieldValue(field)}
                valueObj={this.getFieldValueObj(field)}
                // will look for end_user_company_linked first, if it doesn't exists - will only use accounts_linked
                // selected_accounts={( invoice && invoice.end_user_company_linked && isArrayExists( invoice.end_user_company_linked ) ? invoice.end_user_company_linked : ( invoice && invoice.accounts_linked && isArrayExists( invoice.accounts_linked ) ? invoice.accounts_linked : [] ) )}
                selected_accounts={
                  invoice &&
                  invoice.accounts_linked &&
                  isArrayExists(invoice.accounts_linked)
                    ? invoice.accounts_linked
                    : []
                }
                onChange={(newContacts, key) => {
                  const { invoice, onFormUpdate } = this.props;
                  var newData = invoice ? cloneCollections(invoice) : {};
                  newData[key] =
                    newContacts &&
                    newContacts[0] &&
                    newContacts[0].id &&
                    !_isEmpty(newContacts[0].id)
                      ? [
                          {
                            id: newContacts[0].id,
                            name: newContacts[0].name || "",
                            email: newContacts[0].email || "",
                            account_id: newContacts[0].account_id || "",
                          },
                        ]
                      : [];

                  // do update
                  if (onFormUpdate) onFormUpdate(newData);
                }}
              />
            );
          } else {
            return (
              <FormSelect2
                placeholder="Select an Option"
                {...field}
                value={this.getFieldValue(field)}
                options={this.getFieldOptions(field)}
                onChange={this.handleFormUpdate}
              />
            );
          } // end - id
        case "select":
          return (
            <div style={{ paddingTop: "7px" }}>
              <FormSelect
                {...field}
                value={this.getFieldValue(field)}
                options={this.getFieldOptions(field)}
                disableNative={
                  id === "payment_terms" ||
                  id === "invoice_type" ||
                  id === "business"
                    ? true
                    : false
                }
                onChange={this.handleFormUpdate}
              />
            </div>
          );
        case "multiselect":
          return (
            <div style={{ paddingTop: "7px" }}>
              <FormMultiSelect
                {...field}
                value={this.getFieldValue(field)}
                options={this.getFieldOptions(field)}
                onChange={this.handleFormUpdate}
              />
            </div>
          );
        case "datepicker":
          return (
            <FormDatePicker
              {...field}
              noDefaultVal={true}
              value={this.getFieldValue(field)}
              onChange={this.handleFormUpdate}
            />
          );
      }
    } // end - field.field_type
  };

  renderUpdatesBox = () => {
    const { classes, invoice } = this.props;
    return (
      <div>
        <Typography variant="h4" className={classes.boxheading}>
          Updates
        </Typography>
        <ListUpdates
          list={
            invoice && invoice.updates && isArrayExists(invoice.updates)
              ? invoice.updates
              : false
          }
        />
      </div>
    );
  };

  renderCommentsBox = () => {
    const { classes, invoice, authData, updateActions } = this.props;
    return (
      <div>
        <Typography variant="h4" className={classes.boxheading}>
          Comments
        </Typography>
        <ListComments
          authData={authData || false}
          refer_type="invoice"
          refer_id={
            invoice && invoice.id && !_isEmpty(invoice.id) ? invoice.id : false
          }
          refer_label={
            invoice && invoice.name && !_isEmpty(invoice.name)
              ? invoice.name
              : false
          }
          updateActions={updateActions || false}
          list={
            invoice && invoice.comments && isArrayExists(invoice.comments)
              ? invoice.comments
              : false
          }
        />
      </div>
    );
  };

  renderCompanyDetails = () => {
    const { classes, invoice } = this.props;
    return (
      <FormBox style={{ paddingBottom: "45px" }}>
        <Typography variant="h4" className={classes.boxheading}>
          Entity
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {this.renderField("company_details")}
          </Grid>
        </Grid>
      </FormBox>
    );
  };

  renderAddressDetails = () => {
    const { classes, invoice } = this.props;
    const { copyAddress } = this.state;
    return (
      <FormBox style={{ paddingBottom: "45px" }}>
        <Typography variant="h4" className={classes.boxheading}>
          Address Details
        </Typography>

        <div style={{ marginBottom: "20px" }}>
          <Grid container justify="flex-start" spacing={0}>
            <div style={{ width: "500px" }}>
              <FormSelect
                label="Copy Billing Info From"
                value={copyAddress || ""}
                options={[
                  // { value: '', label: 'None' },
                  { value: "accounts", label: "Account" },
                  { value: "contacts", label: "Contact" },
                ]}
                onChange={(newValue) =>
                  this.setState({ copyAddress: newValue })
                }
              />
            </div>
            <InfoButton
              disabled={copyAddress && !_isEmpty(copyAddress) ? false : true}
              style={{ marginLeft: "5px" }}
              onClick={this.handleCopyAddress}
            >
              <i className="fa fa-clone"></i>Copy
            </InfoButton>
          </Grid>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {this.renderField("billing_address")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("billing_city")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("billing_post_code")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("billing_state")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("billing_country")}
          </Grid>
        </Grid>
      </FormBox>
    );
  };

  renderInvoiceItems = () => {
    const { classes, invoice, regions, selectables, authData } = this.props;
    return (
      <FormBox style={{ paddingBottom: "0px" }}>
        <Typography variant="h4" className={classes.boxheading}>
          Item Details
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            {this.renderField("currency")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("tax_mode")}
          </Grid>
          {invoice &&
          invoice.currency &&
          !_isEmpty(invoice.currency) &&
          invoice.currency !== "RM" ? (
            <Grid item xs={6}>
              {this.renderField("currency_conversion_rate")}
            </Grid>
          ) : null}
          {invoice.tax_mode &&
            (invoice.tax_mode === "individual" ||
              invoice.tax_mode === "group") && (
              <Grid item xs={6}>
                {/* {this.renderField('custom_tax_rate')}
                    <div style={{ fontSize: '0.8em', padding: '5px', fontStyle: 'italic' }}>* Leave it empty to use the default rate</div> */}
                <TaxRate
                  authData={authData || false}
                  item={invoice || false}
                  regions={regions || []}
                  onUpdate={(newValue) => {
                    this.handleFormUpdate(newValue, "custom_tax_rate");
                  }}
                />
              </Grid>
            )}
        </Grid>

        <InvoiceItems
          authData={authData}
          invoice={invoice || false}
          regions={regions || false}
          selectables={selectables || false}
          onFormUpdate={(newData) => {
            const { onFormUpdate } = this.props;
            // do update
            if (onFormUpdate) onFormUpdate(newData);
          }}
          onCalTriggered={(product_id) =>
            this.setState({ openCalModal: true, calModalType: product_id })
          }
        />
      </FormBox>
    );
  };

  renderInvoiceTotals = () => {
    const { invoice, regions, authData, isAddNew } = this.props;
    return (
      <FormBox style={{ paddingBottom: "45px" }}>
        <InvoiceTotals
          isAddNew={isAddNew || false}
          authData={authData}
          invoice={invoice || false}
          regions={regions || false}
          onFormUpdate={(newData) => {
            const { onFormUpdate } = this.props;
            // do update
            if (onFormUpdate) onFormUpdate(newData);
          }}
        />
      </FormBox>
    );
  };

  renderCopyEmailField = () => {
    const { copyEmail } = this.state;
    return (
      <div style={{ marginBottom: "0px" }}>
        <Grid container justify="flex-start" spacing={0}>
          <div style={{ width: "400px" }}>
            <FormSelect
              label="Copy Email From"
              value={copyEmail || ""}
              options={[
                // { value: '', label: 'None' },
                { value: "accounts", label: "Account" },
                { value: "end_user_company", label: "End User Company" },
                { value: "contacts", label: "Contact" },
              ]}
              onChange={(newValue) => this.setState({ copyEmail: newValue })}
            />
          </div>
          <InfoButton
            disabled={copyEmail && !_isEmpty(copyEmail) ? false : true}
            style={{ marginLeft: "5px" }}
            onClick={this.handleCopyEmail}
          >
            <i className="fa fa-clone"></i>Copy
          </InfoButton>
        </Grid>
      </div>
    );
  };

  renderDetailsBox = () => {
    const { classes, invoice, isAddNew } = this.props;
    return (
      <FormBox style={{ paddingBottom: "30px" }}>
        <Typography variant="h4" className={classes.boxheading}>
          Invoice Info
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {this.renderField("name")}
          </Grid>

          <Grid item xs={6}>
            {isAddNew ? null : this.renderField("id")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("status")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("payment_terms")}
          </Grid>

          <Grid item xs={6}>
            {this.renderField("invoice_date")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("payment_date")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("accounts_linked")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("end_user_company_linked")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("contacts_linked")}
          </Grid>

          <Grid item xs={12}>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
          </Grid>

          <Grid item xs={6}>
            {this.renderField("email")}
          </Grid>
          <Grid item xs={6}>
            {this.renderCopyEmailField()}
          </Grid>

          <Grid item xs={6}>
            {this.renderField("po_number")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("delivery_order")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("business")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("invoice_type")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("issued_by")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("sales_person")}
          </Grid>
          <Grid item xs={12}>
            {this.renderField("notes")}
          </Grid>
          <Grid item xs={6}>
            {this.renderField("assigned_to")}
          </Grid>
        </Grid>

        <div
          style={{
            paddingTop: "10px",
            textAlign: "right",
            color: "#999",
            fontSize: "1.25rem",
          }}
        >
          <div>
            {invoice.created_on
              ? "Created on " +
                getMomentTime(invoice.created_on, "YYYY-MM-DD hh:mm:ssa")
              : ""}
          </div>
          <div>
            {invoice.modified_on
              ? "Last Modified on " +
                getMomentTime(invoice.modified_on, "YYYY-MM-DD hh:mm:ssa")
              : ""}
          </div>
        </div>
      </FormBox>
    );
  };

  render() {
    const { isAddNew, authData, regions, invoice } = this.props;
    const { openCalModal, calModalType } = this.state;
    return (
      <div>
        {this.renderCompanyDetails()}
        {this.renderDetailsBox()}
        {this.renderAddressDetails()}
        {this.renderInvoiceItems()}
        {this.renderInvoiceTotals()}

        {isAddNew ? null : (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {this.renderCommentsBox()}
            </Grid>
            <Grid item xs={6}>
              {this.renderUpdatesBox()}
            </Grid>
          </Grid>
        )}

        <Calculator
          authData={authData || false}
          open={openCalModal}
          type={calModalType}
          item={invoice || false}
          regions={regions || []}
          onUpdate={(newValue, key) => {
            this.handleFormUpdate(newValue, key);
            this.setState({ openCalModal: false, calModalType: false });
          }}
          onClose={() =>
            this.setState({ openCalModal: false, calModalType: false })
          }
        />
      </div>
    );
  }
}

export default compose(
  connect(),
  withStyles(useStyles),
  withRouter
)(InvoiceDetails);
