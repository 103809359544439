import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _map from "lodash/map";
import _toString from "lodash/toString";

/* eslint-disable */

import Table from "../../components/Table";
import Pagination from "../../components/PaginationFireStore";
import TableBar from "../../components/TableBarFirestore";
import ModelDelete from "../../components/ModalDelete";
import ButtonActions from "../../components/ButtonActions";
import AddNewProduct from "../../components/AddNewProduct";

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from "../../styles/button";
import { SuccessTag, AmberTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";

import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, combineStrings } from "../../helpers/data";
import { getMomentTime, getMomentTimestamp } from "../../helpers/date";
import { formatMoney } from "../../helpers/number";
import { isAdmin, hasAccessRights, hasLimitedAccess } from "../../helpers/auth";
import { getSearchKeyID, getRawSearchKeywords, doSearchSort } from "../../helpers/firestore";

import { deleteProduct, syncScheduleDataWithLMS, syncCourseDataWithLMS } from "../../actions/products";

class Products extends React.Component {
    state = {
        checked: [],
        allCheck: false,
        openViewModal: false,
        openDeleteModal: false,
        deleteModal: false,
        openComment: false,
        openTransferownership: false,
    };

    // handleScheduleDataSync = () => {
    //     this.props.dispatch(syncScheduleDataWithLMS());
    // }

    // handleCourseDataSync = () => {
    //     this.props.dispatch(syncCourseDataWithLMS());
    // }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if (this.props.onRefresh) this.props.onRefresh();
    };

    handleEdit = (id, event) => {
        event.preventDefault();
        this.props.history.push("/products/" + id);
    };

    handleDelete = () => {
        const { currentQuery } = this.props;
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteProduct(deleteModal.id, currentQuery));
    };

    handleQueryChange = (newQuery) => {
        const { onQueryChange } = this.props;
        // trigger change
        if (onQueryChange) onQueryChange(newQuery);
    };

    getActions = () => {
        const { authData } = this.props;
        let actions = [{ id: "addnew", label: "Add New Product", icon: "fa-plus-circle", onClick: () => this.setState({ openViewModal: true }) }];

        // if ( authData && isAdmin( authData ) && ( authData.email && authData.email === 'webadmin@dreamcatcher.asia' ) ) {
        //     actions.push({ id: 'sync_schedules', label: 'Sync Schedules with LMS', icon: 'fa-random', onClick: this.handleScheduleDataSync });
        //     actions.push({ id: 'sync_courses', label: 'Sync Courses with LMS', icon: 'fa-random', onClick: this.handleCourseDataSync });
        // } // end - authData

        return actions;
    };

    getProductsOptions = () => {
        const { courses } = this.props;
        var options = [];

        if (courses && isArrayExists(courses)) {
            _forEach(
                _sortBy(courses, (c) => parseInt(c.id, 10)),
                (course) => {
                    options.push({ value: course.id, label: "[" + course.group_code + course.id + "] " + course.title });
                }
            );
        } // end - courses

        return options;
    };

    getRegionOptions = () => {
        const { regions } = this.props;
        var options = [{ value: "", label: "All Entities" }];

        if (regions && isArrayExists(regions)) {
            _forEach(regions, (region) => {
                options.push({ value: "region_id_|_" + region.id, label: region.company_name });
            });
        } // end - regions

        return options;
    };

    reorganizeData() {
        const { products, total, authData, filterBy, searchterms, sortBy } = this.props;
        let items = products ? cloneCollections(products) : [],
            totalCount = total ? total : 0;

        // // do search
        // if ( searchterms && !_isEmpty( searchterms ) ) {
        //     items = doArraySearch( items, searchterms, ['name','location','city'] );
        // 	total = _size( items );
        // } // end - searchterms

        // // do filter by region
        // if ( filterBy && !_isEmpty( filterBy ) ) {
        //     items = _filter( items, { region_id: filterBy } );
        //     total = _size( items );
        // } // end - filterBy

        // // do sort
        // if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
        //     switch( sortBy ) {
        //         case 'date-desc':
        //             items = _sortBy( items, (i) => ( i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on ) );
        //             items = _reverse( items );
        //             break;
        //         case 'date-asc':
        //             items = _sortBy( items, (i) => ( i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on ) );
        //             break;
        //         case 'start_date-desc':
        //             items = _sortBy( items, ['start_date'] );
        //             items = _reverse( items );
        //             break;
        //         case 'start_date-asc':
        //             items = _sortBy( items, ['start_date'] );
        //             break;
        //         case 'end_date-desc':
        //             items = _sortBy( items, ['end_date'] );
        //             items = _reverse( items );
        //             break;
        //         case 'end_date-asc':
        //             items = _sortBy( items, ['end_date'] );
        //             break;
        //         case 'location-desc':
        //             items = _sortBy( items, [(i) => i.location.toLowerCase()] );
        //             items = _reverse( items );
        //             break;
        //         case 'location-asc':
        //             items = _sortBy( items, [(i) => i.location.toLowerCase()] );
        //             break;
        //         case 'name-desc':
        //             items = _sortBy( items, [(i) => i.name.toLowerCase()] );
        //             items = _reverse( items );
        //             break;
        //         case 'name-asc':
        //             items = _sortBy( items, [(i) => i.name.toLowerCase()] );
        //             break;
        //     }
        // } // end - sortBy

        // // do pagination
        // items = doPagination( items, perPage, page );

        // for searchterms
        if (searchterms && !_isEmpty(searchterms)) {
            // further filter out unnecessary keywords
            var search_key = getSearchKeyID(searchterms),
                search_keywords = getRawSearchKeywords(searchterms);

            switch (search_key) {
                case "full_name":
                    items = doArraySearch(items, search_keywords, ["full_name_sort"]);
                    totalCount = _size(items);
                    break;
                default:
                    items = doArraySearch(items, search_keywords, [search_key]);
                    totalCount = _size(items);
                    break;
            }

            // do filter if needed
            if (filterBy && !_isEmpty(filterBy)) {
                var filterKey = getSearchKeyID(filterBy),
                    filterVal = getRawSearchKeywords(filterBy);
                if (filterKey && !_isEmpty(filterKey) && filterVal && !_isEmpty(filterVal)) {
                    items = _filter(items, (i) => i[filterKey] === filterVal);
                    totalCount = _size(items);
                }
            } // end - filterBy

            // do sorting on search results
            if (sortBy && !_isEmpty(sortBy)) {
                items = doSearchSort(items, sortBy);
            }
        } // end - searchterms

        return { items, total: totalCount };
    }

    renderCell = (type) => (item) => {
        const { selectables } = this.props;
        switch (type) {
            case "start_date":
            case "end_date":
                return <div>{item[type] ? getMomentTime(item[type], "YYYY-MM-DD") : "-"}</div>;
            case "schedule_status":
                let schedule_status = selectables && isArrayExists(selectables) ? _find(selectables, { id: "schedule_status" }) : false,
                    selected =
                        schedule_status && schedule_status.options && isArrayExists(schedule_status.options)
                            ? _find(schedule_status.options, { id: item[type] })
                            : false;
                return <div>{(selected && selected.label) || ""}</div>;
            case "pricing":
                var currency_list = selectables && isArrayExists(selectables) ? _find(selectables, { id: "currency" }) : false,
                    currency =
                        currency_list && currency_list.options && isArrayExists(currency_list.options) && item.currency && !_isEmpty(item.currency)
                            ? _find(currency_list.options, { id: item.currency })
                            : false;
                return (
                    <div>
                        {item && item.price_category && !_isEmpty(item.price_category)
                            ? _map(item.price_category, (pricing_cat) => {
                                  if (pricing_cat.id && pricing_cat.id === "281") {
                                      return (
                                          <div key={pricing_cat.id}>
                                              {/* separate out pricing for course & schedule  */}
                                              {item.schedule_id && !_isEmpty(item.schedule_id) ? (
                                                  pricing_cat.price && !_isEmpty(pricing_cat.price) ? (
                                                      <strong style={{ fontWeight: "400" }}>
                                                          {(currency && currency.value) || ""}
                                                          {formatMoney(parseFloat(pricing_cat.price), 2, 3)}
                                                      </strong>
                                                  ) : (
                                                      "---"
                                                  )
                                              ) : pricing_cat.price && !_isEmpty(pricing_cat.price) && _toString(pricing_cat.price) !== "0" ? (
                                                  <strong style={{ fontWeight: "400" }}>
                                                      {(currency && currency.value) || ""}
                                                      {formatMoney(parseFloat(pricing_cat.price), 2, 3)}
                                                  </strong>
                                              ) : (
                                                  "---"
                                              )}
                                          </div>
                                      );
                                  } // end - pricing_cat.id
                              })
                            : "---"}
                    </div>
                );
            case "email":
                return <WrapWord>{item[type] || ""}</WrapWord>;
            default:
                return <div>---</div>;
        }
    };

    renderTableActions = () => {
        const { authData, regions, filterBy, sortBy, perPage, searchterms } = this.props;
        const { checked } = this.state;
        return (
            <TableBar
                mod="products"
                sortBy={sortBy}
                filterBy={filterBy || ""}
                filterByPlaceholder="All Entities"
                perPage={perPage}
                limitedPerPage={authData && hasLimitedAccess(authData) ? true : false}
                searchterms={searchterms}
                sortByOptions={[
                    { value: "modified_on-desc", label: "Recent Modified first" },
                    { value: "modified_on-asc", label: "Oldest Modified first" },
                    { value: "start_date-desc", label: "Start Date (Recent first)" },
                    { value: "start_date-asc", label: "Start Date (Oldest first)" },
                    { value: "end_date-desc", label: "End Date (Recent first)" },
                    { value: "end_date-asc", label: "End Date (Oldest first)" },
                    { value: "name_sort-asc", label: "Name ( A - Z)" },
                    { value: "name_sort-desc", label: "Name ( Z - A )" },
                    { value: "location_sort-asc", label: "Location ( A - Z)" },
                    { value: "location_sort-desc", label: "Location ( Z - A )" },
                ]}
                filterByOptions={this.getRegionOptions()}
                leftButtons={
                    checked && isArrayExists(checked)
                        ? [
                              <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                                  <GreyButton
                                      style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }}
                                      onClick={() => this.setState({ checked: [], allCheck: false })}
                                  >
                                      <i className="fa fa-remove" style={{ marginRight: "10px" }}></i>
                                      {_size(checked) + " selected"}
                                  </GreyButton>
                              </div>,
                          ]
                        : null
                }
                rightButtons={[
                    <ButtonActions
                        key="actions"
                        label="Actions"
                        menuContainerStyle={{ width: "200px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()}
                    />,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}>
                        <i className="fa fa-refresh"></i>Refresh
                    </InverseButton>,
                ]}
                onFilterByChange={(newFilterBy) => this.handleQueryChange({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.handleQueryChange({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.handleQueryChange({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.handleQueryChange({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }}
            />
        );
    };

    renderPagination = (totalCount) => {
        const { productsLoaded, perPage, page, searchterms, currentTotal } = this.props;
        return (
            <Pagination
                total={totalCount}
                currentTotal={currentTotal}
                searchterms={searchterms}
                perPage={perPage}
                page={page}
                style={{ marginTop: "20px" }}
                doneLoaded={productsLoaded}
                onPageChange={(newPage) => this.handleQueryChange({ page: newPage })}
            />
        );
    };

    render() {
        const { authData } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, allCheck, checked, openComment, openTransferownership } = this.state;
        const { items, total } = this.reorganizeData();
        return (
            <div>
                <AddNewProduct authData={authData} open={openViewModal} onClose={() => this.setState({ openViewModal: false })} />

                <ModelDelete
                    open={openDeleteModal}
                    title={deleteModal && deleteModal.name ? `Are you sure you want to delete this contact ( ${deleteModal.name} )?` : false}
                    onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                    onDelete={this.handleDelete}
                />

                {this.renderTableActions()}
                <Table
                    noSelect={authData && hasLimitedAccess(authData) ? true : false}
                    items={items}
                    showCheckbox={false}
                    // checked={( checked || [] )}
                    // allCheck={allCheck}
                    // onChecked={(newValue) => this.setState({ checked: newValue })}
                    // onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                    cells={[
                        { id: "name", label: "Name", render: (item) => item.name || "" },
                        { id: "pricing", label: "Regular Fee", render: this.renderCell("pricing") },
                        { id: "location", label: "Location", render: (item) => item.location || "" },
                        { id: "start_date", label: "Course Start Date", render: this.renderCell("start_date") },
                        { id: "end_date", label: "Course End Date", render: this.renderCell("end_date") },
                        { id: "schedule_status", label: "Course Status", render: this.renderCell("schedule_status") },
                    ]}
                    actionStyles={{ width: "10%" }}
                    actions={(item) => (
                        <ButtonGroup>
                            <AInfoLink key="edit" href={"/products/" + item.id} size="small" onClick={this.handleEdit.bind(this, item.id)}>
                                <i className="fa fa-edit"></i>Edit
                            </AInfoLink>
                            {authData && hasAccessRights(authData, ["pdd"]) ? (
                                <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}>
                                    <i className="fa fa-trash"></i>Delete
                                </ErrorButton>
                            ) : null}
                        </ButtonGroup>
                    )}
                />
                {this.renderPagination(total)}
            </div>
        );
    }
}

export default compose(connect(), withRouter)(Products);
