import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _slice from "lodash/slice";
import _split from "lodash/split";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";

/* eslint-disable */

import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormMultiSelect from "../../components/FormMultiSelect";
import FormInput from "../../components/FormInput";
import FormUpload from "../../components/FormUpload";

import { InfoButton, ErrorButton, ButtonGroup, AInfoLink, SuccessButton } from "../../styles/button";
import { WrapWord } from "../../styles/misc";

import { isArrayExists, validateEmail } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import { uploadMediaToStorage } from "../../helpers/firebase";
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from "../../helpers/data";
import { doSchemaErrorCheck, isSkip, isSchemaRequired } from "../../helpers/schemas";

import { regionSchema } from "../../schemas/region";

import { addRegion, editRegion, deleteRegion, syncDataWithLMS } from "../../actions/m_regions";
import { toggleLoader } from "../../actions/global";

const useStyles = (theme) => ({
    headcell: {
        fontSize: "16px",
        fontWeight: "700",
        color: theme.palette.background,
    },
    bodycell: {
        fontSize: "16px",
        verticalAlign: "top",
    },
});

class Regions extends React.Component {
    state = {
        sortBy: "name-asc",
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
    };

    handleDataSync = (event) => {
        event.preventDefault();
        //this.props.dispatch(syncDataWithLMS())
    };

    handleFormUpdate = (newValue, key) => {
        const { modalData } = this.state;
        var newData = modalData ? cloneCollections(modalData) : {};
        newData[key] = newValue;
        this.setState({ modalData: newData });
    };

    handleFileUpload = (files, key) => {
        const { authData, dispatch } = this.props;
        if (files && files[0] && !_isEmpty(files[0])) {
            dispatch(toggleLoader(true));
            uploadMediaToStorage(files[0], "uploads", authData)
                .then((url) => {
                    this.handleFormUpdate(url, key);
                    dispatch(toggleLoader(false));
                })
                .catch((error) => {
                    triggerErrorAlert((error && error.message) || "Upload Error. Please try again");
                });
        } else {
            triggerErrorAlert("Please upload a valid image file");
        }
    };

    handleAddNew = () => {
        const { modalData } = this.state;
        var error = false;

        _forEach(regionSchema, (schema) => {
            if (!doSchemaErrorCheck(modalData[schema.id], schema, "add")) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addRegion(modalData));
        } // end - error
    };

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false;

        _forEach(regionSchema, (schema) => {
            if (!doSchemaErrorCheck(modalData[schema.id], schema, "update")) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editRegion(modalData));
        } // end - error
    };

    handleAction = () => {
        const { modalType } = this.state;
        if (modalType && modalType == "add") {
            this.handleAddNew();
        } else if (modalType && modalType == "update") {
            this.handleEdit();
        } // end - modalType
    };

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteRegion(deleteModal.id));
    };

    getDefaultRegion = () => {
        let region = {};
        _forEach(regionSchema, (schema) => {
            if (!isSkip(schema, "add")) {
                region[schema.id] = schema.default || null;
            }
        });
        return region;
    };

    disableRegionField = (id) => {
        const { modalData } = this.state;
        return modalData && modalData.id && (modalData.id === 2 || modalData.id === "2")
            ? id === "prefix_quote" || id === "prefix_invoice"
                ? true
                : false
            : false;
    };

    reorganizeData() {
        const { sortBy, perPage, page } = this.state;
        const { regions } = this.props;
        var items = regions ? cloneCollections(regions) : [],
            total = _size(items);

        // do sort
        if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
            switch (sortBy) {
                case "date-desc":
                    items = _sortBy(items, ["created_on"]);
                    items = _reverse(items);
                    break;
                case "date-asc":
                    items = _sortBy(items, ["created_on"]);
                    break;
                case "name-desc":
                    items = _sortBy(items, ["label"]);
                    items = _reverse(items);
                    break;
                case "name-asc":
                    items = _sortBy(items, ["label"]);
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination(items, perPage, page);

        return { items, total };
    }

    renderUploadField = (id) => {
        const { modalData } = this.state;
        let schema = _find(regionSchema, { id });
        if (schema)
            return (
                <FormUpload
                    disabled={this.disableRegionField(id)}
                    label={schema.label || ""}
                    name={schema.id || ""}
                    value={modalData[schema.id] || ""}
                    onReset={this.handleFormUpdate}
                    onUpload={this.handleFileUpload}
                />
            );
    };

    renderNumberField = (id) => {
        const { modalData } = this.state;
        let schema = _find(regionSchema, { id });
        if (schema)
            return (
                <FormInput
                    disabled={this.disableRegionField(id)}
                    type="number"
                    label={schema.label || ""}
                    name={schema.id || ""}
                    value={modalData[schema.id] || 0}
                    onChange={this.handleFormUpdate}
                />
            );
    };

    renderTextField = (id) => {
        const { modalData, modalType } = this.state;
        let schema = _find(regionSchema, { id });
        if (schema)
            return (
                <FormInput
                    disabled={this.disableRegionField(id)}
                    rows={schema.field === "textarea" ? 3 : 1}
                    multiline={schema.field === "textarea" ? true : false}
                    label={(schema.label || "") + (isSchemaRequired(schema, modalType) ? " *" : "")}
                    name={schema.id || ""}
                    value={modalData[schema.id] || ""}
                    onChange={this.handleFormUpdate}
                />
            );
    };

    renderViewForm = () => {
        const { modalData, modalType } = this.state;
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {this.renderTextField("label")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderTextField("prefix_quote")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderTextField("prefix_invoice")}
                    </Grid>

                    <Grid item xs={12}>
                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Grid>

                    <Grid item xs={8}>
                        {this.renderTextField("company_name")}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderTextField("company_code")}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderUploadField("company_logo")}
                    </Grid>

                    <Grid item xs={12}>
                        {this.renderTextField("company_address")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderTextField("company_phone")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderTextField("company_fax")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderTextField("company_email")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderTextField("company_website")}
                    </Grid>

                    <Grid item xs={12}>
                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderTextField("tax_label")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderNumberField("tax_amount")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderTextField("tax_reg_number_label")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderTextField("tax_reg_number")}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderTextField("brochure_cover_heading")}
                    </Grid>
                </Grid>
            </div>
        );
    };

    renderTableActions = () => {
        const { sortBy, perPage, searchterms } = this.state;
        return (
            <TableBar
                show={["sort", "entries"]}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: "date-desc", label: "Recent Entries first" },
                    { value: "date-asc", label: "Oldest Entries first" },
                    { value: "name-asc", label: "Label ( A - Z)" },
                    { value: "name-desc", label: "Label ( Z - A )" },
                ]}
                rightButtons={[
                    <InfoButton
                        minWidth="128px"
                        key="addnew"
                        style={{ marginRight: "5px" }}
                        onClick={() =>
                            this.setState({
                                openViewModal: true,
                                modalData: this.getDefaultRegion(),
                                modalType: "add",
                            })
                        }
                    >
                        <i className="fa fa-plus-circle"></i>Add New
                    </InfoButton>,
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }}
            />
        );
    };

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return (
            <Pagination
                total={totalCount}
                perPage={perPage}
                page={page}
                doneLoaded={true}
                style={{ marginTop: "20px" }}
                onPageChange={(newPage) => this.setState({ page: newPage })}
            />
        );
    };

    renderBody = (items) => {
        const { classes } = this.props;
        return (
            <TableBody>
                {items && isArrayExists(items) ? (
                    items.map((item) => {
                        var company_address = item.company_address && !_isEmpty(item.company_address) ? _split(item.company_address, "\n") : false;
                        return (
                            <TableRow key={item.id}>
                                <TableCell className={classes.bodycell}>{item.id || ""}</TableCell>
                                <TableCell className={classes.bodycell}>{item.label || ""}</TableCell>
                                <TableCell className={classes.bodycell}>
                                    {item.company_logo && !_isEmpty(item.company_logo) ? (
                                        <div style={{ marginBottom: "5px" }}>
                                            <img
                                                src={item.company_logo}
                                                style={{
                                                    width: "100%",
                                                    height: "auto",
                                                    maxWidth: "150px",
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                    {item.company_name || ""}
                                    {item.company_code && !_isEmpty(item.company_code) ? " (" + item.company_code + ")" : ""}
                                    <br />
                                    {company_address
                                        ? company_address.map((address, index) => {
                                              return <div key={index}>{address || ""}</div>;
                                          })
                                        : null}
                                    {item.company_phone && !_isEmpty(item.company_phone) ? "Tel: " + item.company_phone : ""}
                                    {item.company_fax && !_isEmpty(item.company_fax) ? " / Fax: " + item.company_fax : ""}
                                    <br />
                                    {item.tax_reg_number_label && !_isEmpty(item.tax_reg_number_label) ? item.tax_reg_number_label + ":" : ""}
                                    {item.tax_reg_number && !_isEmpty(item.tax_reg_number) ? " " + item.tax_reg_number : ""}
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    <ButtonGroup>
                                        <InfoButton
                                            size="small"
                                            onClick={() =>
                                                this.setState({
                                                    openViewModal: true,
                                                    modalData: item,
                                                    modalType: "update",
                                                })
                                            }
                                        >
                                            <i className="fa fa-edit"></i>Edit
                                        </InfoButton>
                                        {item.id && (parseInt(item.id, 10) === 3 || parseInt(item.id, 10) === 2) ? null : (
                                            <ErrorButton
                                                size="small"
                                                onClick={() =>
                                                    this.setState({
                                                        openDeleteModal: true,
                                                        deleteModal: item,
                                                    })
                                                }
                                            >
                                                <i className="fa fa-trash"></i>Delete
                                            </ErrorButton>
                                        )}
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell className={classes.bodycell}>No Entity found.</TableCell>
                    </TableRow>
                )}
            </TableBody>
        );
    };

    renderHeader = () => {
        const { classes } = this.props;
        return (
            <TableHead>
                <TableRow>
                    <TableCell className={classes.headcell} style={{ width: "10%" }}>
                        ID
                    </TableCell>
                    <TableCell className={classes.headcell} style={{ width: "30%" }}>
                        Label
                    </TableCell>
                    <TableCell className={classes.headcell}>Entity Details</TableCell>
                    <TableCell className={classes.headcell}>Actions</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    render() {
        const { openDeleteModal, deleteModal, openViewModal, modalType } = this.state;
        const { items, total } = this.reorganizeData();
        return (
            <div>
                <ModalView
                    open={openViewModal}
                    maxWidth="md"
                    title={modalType && modalType == "update" ? "Edit Entity" : "Add New Entity"}
                    actionLabel={modalType && modalType == "update" ? "Update" : "Add New"}
                    onClose={() =>
                        this.setState({
                            openViewModal: false,
                            modalType: false,
                            modalData: false,
                        })
                    }
                    doAction={this.handleAction}
                    contents={this.renderViewForm()}
                />

                <ModelDelete
                    open={openDeleteModal}
                    title={deleteModal && deleteModal.name ? `Are you sure you want to delete this region ( ${deleteModal.name} )?` : false}
                    onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                    onDelete={this.handleDelete}
                />

                {this.renderTableActions()}
                <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                    <Table>
                        {this.renderHeader()}
                        {this.renderBody(items)}
                    </Table>
                </Paper>
                {this.renderPagination(total)}
            </div>
        );
    }
}

export default compose(connect(), withStyles(useStyles), withRouter)(Regions);
