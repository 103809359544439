import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import _isEmpty from "lodash/isEmpty";
import _toLower from "lodash/toLower";

/* eslint-disable */

import AppWrapper from "../../components/AppWrapper";
import ModalView from "../../components/ModalView";
import ButtonActions from "../../components/ButtonActions";
import SnackBarSave from "../../components/SnackBarSave";
import ItemNotFound from "../../components/ItemNotFound";
import AddNewEmail from "../../components/AddNewEmail";

import Details from "./details";

import { InfoButton, GreyButton } from "../../styles/button";

import { hasAccessRights } from "../../helpers/auth";
import { isArrayExists } from "../../helpers/validation";
import { cloneCollections } from "../../helpers/data";
import { triggerErrorAlert } from "../../helpers/alert";
import { doSchemaErrorCheck } from "../../helpers/schemas";
import { newInvoiceItem } from "../../helpers/invoices";

import {
  getInvoice,
  editInvoice,
  generateInvoicePDF,
  duplicateInvoice,
} from "../../actions/invoices";
import { getUsersOptions } from "../../actions/users";
import { getSelectables } from "../../actions/m_selectables";
import { getRegions } from "../../actions/m_regions";
import { getCoursePortfolios } from "../../actions/m_course_portfolio";
import { appChangesMade, resetRedux } from "../../actions/misc";
import { getInvoiceType } from "../../actions/m_invoice_type";
import { getPaymentTerms } from "../../actions/m_payment_terms";

import { invoiceSchema } from "../../schemas/invoice";

import { FIREBASE_STORAGE_URL } from "../../constants";

class InvoicePage extends React.Component {
  state = {
    invoice: false,
    openViewModal: false,
    modalType: false,
    modalContent: false,
    openSendEmail: false,
    randNum: false,
  };

  componentDidMount() {
    const { invoice_id } = this.props.match.params;

    // retrieve invoice details
    this.props.dispatch(getInvoice(invoice_id));

    // get regions
    if (!this.props.regionsList) this.props.dispatch(getRegions());

    // get selectables
    if (!this.props.selectablesList) this.props.dispatch(getSelectables());

    // get all users
    if (!this.props.usersOptions) this.props.dispatch(getUsersOptions());

    // get course portfolio
    if (!this.props.coursePortfolioList)
      this.props.dispatch(getCoursePortfolios());

    // get invoice type
    if (!this.props.invoiceTypeList) this.props.dispatch(getInvoiceType());

    // get payment terms
    if (!this.props.paymentTermsList) this.props.dispatch(getPaymentTerms());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      currentInvoice,
      regionsList,
      selectablesList,
      coursePortfolioList,
      invoiceTypeList,
      paymentTermsList,
      usersOptions,
      randNum,
    } = this.props;

    // for invoice
    if (
      currentInvoice &&
      regionsList &&
      selectablesList &&
      coursePortfolioList &&
      invoiceTypeList &&
      paymentTermsList &&
      usersOptions &&
      randNum &&
      randNum !== this.state.randNum
    ) {
      this.setState({ invoice: this.getInvoiceData(currentInvoice), randNum });
    } // end - currentInvoice
  }

  componentWillUnmount() {
    this.props.dispatch(resetRedux("invoice"));
  }

  getInvoiceData = (currentInvoice) => {
    var invoice = cloneCollections(currentInvoice);

    // add empty row for products if needed
    if (!(invoice.products_linked && isArrayExists(invoice.products_linked))) {
      invoice.products_linked = [];
      invoice.products_linked.push(newInvoiceItem(0));
    } // end - invoice

    return invoice;
  };

  handleSaveChanges = (event) => {
    const { authData } = this.props;
    const { invoice } = this.state;
    event.preventDefault();
    var error = false,
      formData = {};

    // do error check
    invoiceSchema.forEach((schema) => {
      formData[schema.id] =
        invoice && invoice[schema.id]
          ? cloneCollections(invoice[schema.id])
          : schema.default;

      // insert invoice_date if is empty
      if (schema.id === "invoice_date" && !formData[schema.id]) {
        formData[schema.id] = moment().utcOffset(8).valueOf();
      } // end - schema.id

      // insert default value if is empty
      if (
        schema.id === "issued_by" &&
        !(formData[schema.id] && !_isEmpty(formData[schema.id]))
      ) {
        formData[schema.id] = (authData && authData.email) || "";
      } // end - schema.id

      if (!doSchemaErrorCheck(formData[schema.id], schema, "update")) {
        error = 'Please fill out "' + schema.label + '" field';
      }
    });

    if (error) {
      triggerErrorAlert(error);
    } else {
      this.props.dispatch(editInvoice(formData));
    } // end - error
  };

  handleActionConfirmation = () => {
    const { invoice, modalType } = this.state;
    switch (modalType) {
      case "generate-pdf":
        this.props.dispatch(generateInvoicePDF(invoice.id));
        break;
      case "duplicate-invoice":
        //this.props.dispatch( duplicateInvoice(invoice.id,this.props.history) );
        this.props.history.push(
          "/invoices/new?action=duplicate&id=" + invoice.id
        );
        break;
      case "create-credit-note":
        this.props.history.push(
          "/credit_notes/new?action=convert&id=" + invoice.id
        );
        break;
    } // end - type
    this.setState({
      openViewModal: false,
      modalType: false,
      modalContent: false,
    });
  };

  handleButtonActions = (type) => {
    const { invoice } = this.state;
    const { changesMade } = this.props;
    var error = false,
      formData = {};
    // if changes made - trigger error
    if (changesMade) {
      alert("Please save changes first before proceed");
    } else {
      switch (type) {
        case "generate-pdf":
        case "duplicate-invoice":
        case "create-credit-note":
          // do update error check
          invoiceSchema.forEach((schema) => {
            formData[schema.id] =
              invoice && invoice[schema.id]
                ? cloneCollections(invoice[schema.id])
                : schema.default;
            if (!doSchemaErrorCheck(formData[schema.id], schema, "update")) {
              error = 'Please fill out "' + schema.label + '" field';
            }
          });

          if (error) {
            triggerErrorAlert(error);
          } else {
            var modalMessage = "";
            if (type == "generate-pdf") {
              modalMessage =
                "You're about to export this invoice to PDF. Click CONFIRM to continue";
            } else if (type == "duplicate-invoice") {
              modalMessage =
                "You're about to duplicate this invoice. Click CONFIRM to continue";
            } else if (type == "create-credit-note") {
              modalMessage =
                "You're about to create a credit note based on this invoice. Click CONFIRM to continue";
            }
            this.setState({
              openViewModal: true,
              modalType: type,
              modalContent: modalMessage,
            });
          } // end - error
          break;
        case "sendemail":
          this.setState({ openSendEmail: true });
          break;
      } // end - type
    } // end - changesMade
  };

  handleFormUpdate = (newValue) => {
    this.setState({ invoice: newValue });

    // trigger changes made
    this.props.dispatch(appChangesMade());
  };

  getActions = () => {
    const { authData } = this.props;
    const { invoice } = this.state;
    let actions = [];

    if (invoice && invoice.pdf_id && !_isEmpty(invoice.pdf_id)) {
      actions.push({
        id: "download-pdf",
        label: "View PDF",
        icon: "fa-download",
        onClick: () => {
          let link =
            FIREBASE_STORAGE_URL +
            "invoices%2F" +
            invoice.pdf_id +
            "_" +
            invoice.id +
            ".pdf?alt=media";
          window.open(link, "_blank");
        },
      });
    } // end - invoice

    // if pdf already exists - only those has the modify access can modify it
    if (invoice && invoice.pdf_id && !_isEmpty(invoice.pdf_id)) {
      if (hasAccessRights(authData, ["ivm"]))
        actions.push({
          id: "generate-pdf",
          label: "Export Invoice To PDF",
          icon: "fa-file-pdf-o",
          onClick: this.handleButtonActions.bind(this, "generate-pdf"),
        });
    } else {
      actions.push({
        id: "generate-pdf",
        label: "Export Invoice To PDF",
        icon: "fa-file-pdf-o",
        onClick: this.handleButtonActions.bind(this, "generate-pdf"),
      });
    } // end - invoice

    // disabled if invoice wasn't in PDF format yet
    if (invoice && invoice.pdf_id && !_isEmpty(invoice.pdf_id)) {
      actions.push({
        id: "sendemail",
        label: "Send email with Invoice PDF",
        icon: "fa-envelope",
        disabled: authData && hasAccessRights(authData, ["ivs"]) ? false : true,
        onClick: this.handleButtonActions.bind(this, "sendemail"),
      });
    } // end - invoice

    actions.push({
      id: "duplicate-invoice",
      label: "Duplicate Invoice",
      icon: "fa-clone",
      onClick: this.handleButtonActions.bind(this, "duplicate-invoice"),
    });

    actions.push({
      id: "create-credit-note",
      label: "Create Credit Note",
      icon: "fa-cogs",
      onClick: this.handleButtonActions.bind(this, "create-credit-note"),
    });

    return actions;
  };

  renderActionsButton = () => {
    return (
      <div style={{ textAlign: "right", paddingTop: "15px" }}>
        <ButtonActions
          key="actions"
          label="Actions"
          color="inverse"
          menuContainerStyle={{ width: "300px" }}
          style={{ padding: "15px 45px" }}
          actions={this.getActions()}
        />
      </div>
    );
  };

  renderdetails = () => {
    const { invoice_id } = this.props.match.params;
    const {
      authData,
      usersOptions,
      regionsList,
      selectablesList,
      coursePortfolioList,
      invoiceTypeList,
      paymentTermsList,
      changesMade,
    } = this.props;
    const { invoice } = this.state;
    return (
      <Details
        authData={authData || false}
        invoice={invoice || false}
        regions={regionsList || []}
        course_portfolio={coursePortfolioList || []}
        selectables={selectablesList || []}
        invoice_type={invoiceTypeList || []}
        payment_terms={paymentTermsList || []}
        changesMade={changesMade || false}
        onFormUpdate={this.handleFormUpdate}
        updateActions={[getInvoice(invoice_id)]}
        users={usersOptions || []}
      />
    );
  };

  renderContents() {
    const { authData, usersOptions } = this.props;
    const { invoice, openViewModal, modalType, modalContent, openSendEmail } =
      this.state;
    return (
      <div>
        <Paper
          elevation={3}
          style={{
            padding: "45px 30px",
            background: "#fff",
            marginTop: "15px",
          }}
        >
          {this.renderdetails()}
        </Paper>

        <Grid container style={{ marginTop: "45px" }}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <InfoButton
              style={{ padding: "15px 5px" }}
              minWidth="250px"
              onClick={this.handleSaveChanges}
            >
              <i className="fa fa-save"></i>Save Changes
            </InfoButton>
          </Grid>
          {/* <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {this.renderActionsButton()}
                </Grid> */}
        </Grid>

        <SnackBarSave onSave={this.handleSaveChanges} />

        <ModalView
          open={openViewModal}
          title={"Please Confirm Your Action"}
          actionLabel="Confirm"
          maxWidth="sm"
          onClose={() =>
            this.setState({
              openViewModal: false,
              modalType: false,
              modalContent: false,
            })
          }
          doAction={this.handleActionConfirmation}
          contents={
            <div>
              {modalContent && !_isEmpty(modalContent) ? (
                <Typography variant="body1">{modalContent}</Typography>
              ) : (
                ""
              )}
            </div>
          }
        />

        {authData && hasAccessRights(authData, ["ivs"]) ? (
          <AddNewEmail
            authData={authData}
            open={openSendEmail}
            users={usersOptions || []}
            predefined_accounts={
              invoice &&
              invoice.end_user_company_linked &&
              isArrayExists(invoice.end_user_company_linked)
                ? invoice.end_user_company_linked
                : false
            }
            predefined_contacts={
              invoice &&
              invoice.contacts_linked &&
              isArrayExists(invoice.contacts_linked)
                ? invoice.contacts_linked
                : false
            }
            predefined_invoices={
              invoice && invoice.id && !_isEmpty(invoice.id) ? [invoice] : false
            }
            onClose={() => this.setState({ openSendEmail: false })}
          />
        ) : null}
      </div>
    );
  }

  render() {
    const { randNum } = this.state;
    const { currentInvoice } = this.props;
    return (
      <AppWrapper
        title="Edit Invoice"
        subtitle="Invoices"
        back="/invoices"
        breadcrumbs={[
          { url: "/invoices", label: "Invoices" },
          { label: "Edit" },
        ]}
        customColumn={
          randNum &&
          currentInvoice &&
          currentInvoice.id &&
          !_isEmpty(currentInvoice.id)
            ? this.renderActionsButton()
            : null
        }
        onLoad={!randNum ? true : false}
        contents={
          currentInvoice &&
          currentInvoice.id &&
          !_isEmpty(currentInvoice.id) ? (
            this.renderContents()
          ) : (
            <ItemNotFound item="Invoice" />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authData: (state.auth && state.auth.user) || null,
    currentInvoice: (state.invoices && state.invoices.invoice) || null,
    randNum: (state.invoices && state.invoices.rand) || null,
    usersOptions: (state.users && state.users.users_options) || null,
    selectablesList:
      (state.maintenance && state.maintenance.selectables) || null,
    regionsList: (state.maintenance && state.maintenance.regions) || null,
    coursePortfolioList:
      (state.maintenance && state.maintenance.course_portfolio) || null,
    invoiceTypeList:
      (state.maintenance && state.maintenance.invoice_type) || null,
    paymentTermsList:
      (state.maintenance && state.maintenance.payment_terms) || null,
    changesMade: (state.misc && state.misc.changes_made) || null,
  };
};

export default compose(connect(mapStateToProps), withRouter)(InvoicePage);
