import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* eslint-disable */

import AppWrapper from "../../components/AppWrapper";
import Regions from "./regions";

import { getRegions } from "../../actions/m_regions";

class MaintenanceRegions extends React.Component {
  state = {
    randNum: false,
  };

  componentDidMount() {
    // get regions
    this.props.dispatch(getRegions());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { regionsList, randNum } = this.props;

    if (regionsList && randNum && randNum != this.state.randNum)
      this.setState({ randNum });
  }

  renderContents() {
    const { regionsList, authData } = this.props;
    console.log(regionsList);
    return <Regions authData={authData} regions={regionsList} />;
  }

  render() {
    const { regionsList, randNum } = this.props;
    return (
      <AppWrapper
        title="Entities"
        subtitle="Maintenance"
        onLoad={!(regionsList && randNum) ? true : false}
        contents={this.renderContents()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authData: (state.auth && state.auth.user) || null,
    regionsList: (state.maintenance && state.maintenance.regions) || null,
    randNum: (state.maintenance && state.maintenance.rand) || null,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter
)(MaintenanceRegions);
