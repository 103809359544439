import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _forEach from "lodash/forEach";
import _upperFirst from "lodash/upperFirst";
import _snakeCase from "lodash/snakeCase";

/* eslint-disable */

import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormSelect from "../../components/FormSelect";
import FormInput from "../../components/FormInput";

import { InfoButton, ErrorButton, ButtonGroup, SuccessButton } from "../../styles/button";
import { WrapWord } from "../../styles/misc";
import { SuccessTag, AmberTag, GreyTag } from "../../styles/tag";

import { isArrayExists, validateEmail } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from "../../helpers/data";

import { addUser, editUser, deleteUser, syncUsers } from "../../actions/users";

const useStyles = (theme) => ({
    headcell: {
        fontSize: "16px",
        fontWeight: "700",
        color: theme.palette.background,
    },
    bodycell: {
        fontSize: "16px",
        verticalAlign: "top",
    },
});

class Users extends React.Component {
    state = {
        searchterms: "",
        filterBy: "all",
        sortBy: "date-desc",
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
    };

    handleExportRules = (event) => {
        event.preventDefault();
        const { users, authData } = this.props;
        var text = "";

        if (users && isArrayExists(users)) {
            _forEach(users, (user) => {
                if (
                    user.email &&
                    validateEmail(user.email) &&
                    user.email !== "webadmin@dreamcatcher.asia" &&
                    user.email !== "khkoay@dreamcatcher.asia"
                ) {
                    text += (!_isEmpty(text) ? "," : "") + '"assigned_to/' + _snakeCase(user.email) + '/id"';
                } // end - user.email
            });
        } // end - users

        var blob = new Blob([text], { type: "text/plain;charset=utf-8;" }); //new way
        var uri = window.URL.createObjectURL(blob);
        var link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "export_users_rules.txt");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    handleDataSync = (event) => {
        event.preventDefault();
        // this.props.dispatch(syncUsers())
    };

    handleFormUpdate = (newValue, key) => {
        const { modalData } = this.state;
        var newData = modalData ? cloneCollections(modalData) : {};
        newData[key] = newValue;
        this.setState({ modalData: newData });
    };

    handleAddNew = () => {
        const { modalData } = this.state;
        var error = false;

        if (!(modalData && modalData.region && !_isEmpty(modalData.region))) {
            error = "Please select a region";
        } // end - modalData.role

        if (!(modalData && modalData.role && !_isEmpty(modalData.role))) {
            error = "Please select a role";
        } // end - modalData.role

        if (!(modalData && modalData.email && validateEmail(modalData.email))) {
            error = "Please insert a valid email address";
        } // end - modalData.email

        if (!(modalData && modalData.name && !_isEmpty(modalData.name))) {
            error = "Please insert a valid name";
        } // end - modalData.name

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addUser(modalData));
        } // end - error
    };

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false;

        if (!(modalData && modalData.region && !_isEmpty(modalData.region))) {
            error = "Please select a region";
        } // end - modalData.role

        if (!(modalData && modalData.role && !_isEmpty(modalData.role))) {
            error = "Please select a role";
        } // end - modalData.role

        if (!(modalData && modalData.status && !_isEmpty(modalData.status))) {
            error = "Please select a status";
        } // end - modalData.role

        if (!(modalData && modalData.name && !_isEmpty(modalData.name))) {
            error = "Please insert a valid name";
        } // end - modalData.name

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editUser(modalData));
        } // end - error
    };

    handleAction = () => {
        const { modalType } = this.state;
        if (modalType && modalType == "new") {
            this.handleAddNew();
        } else if (modalType && modalType == "edit") {
            this.handleEdit();
        } // end - modalType
    };

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteUser(deleteModal.uid));
    };

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { users } = this.props;
        var items = users ? cloneCollections(users) : [],
            total = _size(items);

        // do search
        if (searchterms && !_isEmpty(searchterms)) {
            items = doArraySearch(items, searchterms, ["name", "email", "role"]);
            total = _size(items);
        } // end - searchterms

        // do filter
        if (filterBy && !_isEmpty(filterBy) && filterBy != "all") {
            items = _filter(items, { region: filterBy });
            total = _size(items);
        }

        // do sort
        if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
            switch (sortBy) {
                case "date-desc":
                    items = _sortBy(items, ["created_on"]);
                    items = _reverse(items);
                    break;
                case "date-asc":
                    items = _sortBy(items, ["created_on"]);
                    break;
                case "name-desc":
                    items = _sortBy(items, ["name"]);
                    items = _reverse(items);
                    break;
                case "name-asc":
                    items = _sortBy(items, ["name"]);
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination(items, perPage, page);

        return { items, total };
    }

    renderViewForm = () => {
        const { regions, roles } = this.props;
        const { modalData, modalType } = this.state;
        return (
            <div>
                {modalType && modalType == "edit" ? (
                    <div style={{ marginTop: "8px" }}>
                        <FormSelect
                            label="Status (Required)"
                            name="status"
                            value={modalData.status || ""}
                            options={[
                                { value: "active", label: "Active" },
                                { value: "pending", label: "Pending" },
                                { value: "disabled", label: "Disabled" },
                            ]}
                            onChange={this.handleFormUpdate}
                        />
                    </div>
                ) : null}
                <FormInput label="Name (Required)" name="name" value={modalData.name || ""} onChange={this.handleFormUpdate} />
                {modalType && modalType == "edit" ? null : (
                    <FormInput label="Email (Required)" type="email" name="email" value={modalData.email || ""} onChange={this.handleFormUpdate} />
                )}
                <FormInput label="Phone" name="phone" value={modalData.phone || ""} onChange={this.handleFormUpdate} />
                <div style={{ paddingTop: "10px" }}>
                    <FormSelect
                        label="Role (Required)"
                        name="role"
                        value={modalData.role || ""}
                        options={getSelectOptions({
                            list: roles,
                            options: [
                                { value: "", label: "Select an Option" },
                                { value: "admin", label: "Admin" },
                            ],
                            keys: { value: "id", label: "label" },
                            sortBy: "label",
                        })}
                        onChange={this.handleFormUpdate}
                    />
                </div>
                <div style={{ paddingTop: "15px" }}>
                    <FormSelect
                        label="Entity (Required)"
                        name="region"
                        value={modalData.region || ""}
                        options={getSelectOptions({
                            list: regions ? _filter(regions, (r) => (parseInt(r.id) > 100 ? true : false)) : [],
                            options: [{ value: "", label: "Select an Option" }],
                            keys: { value: "id", label: "label" },
                            sortBy: "label",
                        })}
                        onChange={this.handleFormUpdate}
                    />
                </div>
            </div>
        );
    };

    renderRightButtons = () => {
        const { authData } = this.props;
        let buttons = [];
        if (authData && authData.email && authData.email === "webadmin@dreamcatcher.asia") {
            buttons.push(
                <SuccessButton minWidth="128px" key="download" style={{ marginRight: "5px" }} onClick={this.handleExportRules}>
                    <i className="fa fa-download"></i>Export Rules
                </SuccessButton>
            );
        } // end - authData
        // don't need it anymore
        // buttons.push( <SuccessButton minWidth="168px" key="sync" style={{ marginRight: "5px" }} onClick={this.handleDataSync}><i className="fa fa-refresh"></i>Sycn with LMS</SuccessButton> );

        buttons.push(
            <InfoButton
                minWidth="128px"
                key="addnew"
                onClick={() =>
                    this.setState({
                        openViewModal: true,
                        modalData: {
                            name: "",
                            email: "",
                            region: "",
                            role: "",
                        },
                        modalType: "new",
                    })
                }
            >
                <i className="fa fa-plus-circle"></i>Add New
            </InfoButton>
        );

        return buttons;
    };

    renderTableActions = () => {
        const { regions, roles } = this.props;
        const { filterBy, sortBy, perPage, searchterms } = this.state;
        return (
            <TableBar
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: "date-desc", label: "Recent Entries first" },
                    { value: "date-asc", label: "Oldest Entries first" },
                    { value: "name-asc", label: "Name ( A - Z)" },
                    { value: "name-desc", label: "Name ( Z - A )" },
                ]}
                filterByOptions={getSelectOptions({
                    list: regions,
                    options: [{ value: "all", label: "All" }],
                    keys: { value: "id", label: "company_name" },
                    sortBy: "company_name",
                })}
                rightButtons={this.renderRightButtons()}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }}
            />
        );
    };

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return (
            <Pagination
                total={totalCount}
                perPage={perPage}
                page={page}
                doneLoaded={true}
                style={{ marginTop: "20px" }}
                onPageChange={(newPage) => this.setState({ page: newPage })}
            />
        );
    };

    renderBody = (items) => {
        const { classes, regions, roles, authData } = this.props;
        return (
            <TableBody>
                {items && isArrayExists(items) ? (
                    items.map((item) => {
                        var region = regions && item.region && !_isEmpty(item.region) ? _find(regions, { id: item.region }) : false,
                            role = roles && item.role && !_isEmpty(item.role) ? _find(roles, { id: item.role }) : false;
                        return (
                            <TableRow key={item.uid} id={item.uid}>
                                <TableCell className={classes.bodycell}>
                                    {item.status && item.status == "active" ? (
                                        <SuccessTag>{item.status}</SuccessTag>
                                    ) : item.status && item.status == "pending" ? (
                                        <AmberTag>{item.status}</AmberTag>
                                    ) : (
                                        <GreyTag>disabled</GreyTag>
                                    )}
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    <WrapWord>{item.name || ""}</WrapWord>
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    <WrapWord>{item.email || ""}</WrapWord>
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    {item.type && item.type == "admin" ? "Admin" : role && role.label && !_isEmpty(role.label) ? role.label : ""}
                                </TableCell>
                                <TableCell className={classes.bodycell}>{region && region.company_name ? region.company_name : ""}</TableCell>
                                {item && !(item.lms_uid && !_isEmpty(item.lms_uid)) ? (
                                    <TableCell className={classes.bodycell}>
                                        <ButtonGroup>
                                            <InfoButton
                                                size="small"
                                                onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: "edit" })}
                                            >
                                                <i className="fa fa-edit"></i>Edit
                                            </InfoButton>
                                            <ErrorButton
                                                size="small"
                                                disabled={item.uid && item.uid == authData.uid ? "yes" : false}
                                                onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}
                                            >
                                                <i className="fa fa-trash"></i>Delete
                                            </ErrorButton>
                                        </ButtonGroup>
                                    </TableCell>
                                ) : (
                                    <TableCell className={classes.bodycell}> </TableCell>
                                )}
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell className={classes.bodycell}>No List(s) found.</TableCell>
                    </TableRow>
                )}
            </TableBody>
        );
    };

    renderHeader = () => {
        const { classes } = this.props;
        return (
            <TableHead>
                <TableRow>
                    <TableCell className={classes.headcell}>Status</TableCell>
                    <TableCell className={classes.headcell} style={{ width: "20%" }}>
                        Name
                    </TableCell>
                    <TableCell className={classes.headcell} style={{ width: "20%" }}>
                        Email
                    </TableCell>
                    <TableCell className={classes.headcell}>Role</TableCell>
                    <TableCell className={classes.headcell}>Entity</TableCell>
                    <TableCell className={classes.headcell} style={{ width: "20%" }}>
                        Actions
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    };

    render() {
        const { openDeleteModal, deleteModal, openViewModal, modalType } = this.state;
        const { items, total } = this.reorganizeData();
        console.log(openDeleteModal);
        return (
            <div>
                <ModalView
                    open={openViewModal}
                    title={modalType && modalType == "edit" ? "Edit User" : "Add New User"}
                    actionLabel={modalType && modalType == "edit" ? "Update" : "Add New"}
                    onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                    doAction={this.handleAction}
                    contents={this.renderViewForm()}
                />

                <ModelDelete
                    open={openDeleteModal}
                    title={deleteModal && deleteModal.name ? `Are you sure you want to delete this user ( ${deleteModal.name} )?` : false}
                    onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                    onDelete={this.handleDelete}
                />

                {this.renderTableActions()}
                <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                    <Table>
                        {this.renderHeader()}
                        {this.renderBody(items)}
                    </Table>
                </Paper>
                {this.renderPagination(total)}
            </div>
        );
    }
}

export default compose(connect(), withStyles(useStyles), withRouter)(Users);
