export const regionSchema = [
    {
        id: "id",
        label: "ID",
        required: ["update", "delete"],
        default: "",
        type: "string",
        validation: "string_id",
        skip: ["add"],
    },
    {
        id: "label",
        label: "Label",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "company_name",
        label: "Entity Name",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "company_logo",
        label: "Entity Logo",
        required: false,
        default: "",
        type: "string",
        field: "image_upload",
    },
    {
        id: "company_code",
        label: "Entity Code",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "company_address",
        label: "Entity Address",
        required: false,
        default: "",
        type: "string",
        field: "textarea",
    },
    {
        id: "company_email",
        label: "Entity Email",
        required: false,
        default: "",
        type: "email",
        field: "email",
    },
    {
        id: "company_phone",
        label: "Entity Phone No.",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "company_fax",
        label: "Entity Fax No.",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "company_website",
        label: "Entity Website",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "tax_label",
        label: "Tax Label",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "tax_amount",
        label: "Tax Amount",
        required: false,
        default: 0,
        type: "number",
        field: "text_number",
    },
    {
        id: "tax_reg_number_label",
        label: "Tax Registration Label",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "tax_reg_number",
        label: "Tax Registration Number",
        required: false,
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "prefix_quote",
        label: "Quote Prefix",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "prefix_invoice",
        label: "Invoice Prefix",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "text",
    },
    {
        id: "brochure_cover_heading",
        label: "Brochure Cover Heading",
        required: false,
        default: "",
        type: "string",
        field: "textarea",
    },
];
