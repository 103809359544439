/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import _forEach from "lodash/forEach";
import _map from "lodash/map";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _toString from "lodash/toString";
import _isEqual from "lodash/isEqual";

import ModalView from "../ModalView";
import Table from "../Table";
import Pagination from "../Pagination";
import TableBar from "../TableBar";
import DotsLoader from "../DotsLoader";

import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from "../../helpers/data";
import { getMomentTime, getMomentTimestamp } from "../../helpers/date";
import { formatMoney } from "../../helpers/number";

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from "../../styles/button";

import { getProductsOptions } from "../../actions/products";
import { getRegions } from "../../actions/m_regions";
import { resetRedux } from "../../actions/misc";

class ModalSelectProducts extends React.Component {
    state = {
        searchterms: "",
        filterBy: "",
        sortBy: "start_date-desc",
        perPage: 20,
        page: 1,
        randNum: false,
    };

    componentDidMount() {
        const { authData } = this.props;
        this.setState({ filterBy: (authData && authData.region) || "" });

        this.dispatchOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            !_isEqual(prevProps.productsOptions, this.props.productsOptions) ||
            !_isEqual(prevProps.regionsList, this.props.regionsList) ||
            !_isEqual(prevProps.randNum, this.props.randNum)
        ) {
            this.dispatchOptions();
        }
    }

    dispatchOptions = () => {
        const { productsOptions, regionsList, randNum, authData } = this.props;
        // get regions
        if (!regionsList) this.props.dispatch(getRegions());

        // get products
        if (!productsOptions) {
            this.props.dispatch(getProductsOptions());
        } else if (productsOptions && regionsList && randNum && randNum != this.state.randNum) {
            this.setState({ randNum });
        } // end - productsOptions
    };

    handleProductSelect = (product) => {
        const { onProductSelect } = this.props;
        var selectedProduct = {
            product_id: (product && product.id) || "",
            name: (product && product.name) || "",
            unit_price: 0,
            pricing_category: product.pricing_category && !_isEmpty(product.pricing_category) ? cloneCollections(product.pricing_category) : [],
        };

        // setup unit_price using the regular fee
        var pricing_cat = product.pricing_category && !_isEmpty(product.pricing_category) ? _find(product.pricing_category, { id: "281" }) : false;
        if (pricing_cat && pricing_cat.price) {
            selectedProduct.unit_price = parseFloat(pricing_cat.price, 10);
        } // end - pricing_cat

        // trigger product select
        if (onProductSelect) onProductSelect(selectedProduct);
    };

    handleClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
    };

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { productsOptions, showCoreCourseOnly } = this.props;
        let items = productsOptions
                ? showCoreCourseOnly
                    ? _filter(productsOptions, (p) => !(p.start_date && p.end_date))
                    : cloneCollections(productsOptions)
                : [],
            total = _size(items);

        // do search
        if (searchterms && !_isEmpty(searchterms)) {
            items = doArraySearch(items, searchterms, ["name", "location", "city"]);
            total = _size(items);
        } // end - searchterms

        // do region filter
        if (filterBy && !_isEmpty(filterBy)) {
            items = _filter(items, { region_id: filterBy });
            total = _size(items);
        } // end - filterBy

        // do sort
        if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
            switch (sortBy) {
                case "date-desc":
                    items = _sortBy(items, ["modified_on"]);
                    items = _reverse(items);
                    break;
                case "date-asc":
                    items = _sortBy(items, ["modified_on"]);
                    break;
                case "start_date-desc":
                    items = _sortBy(items, ["start_date"]);
                    items = _reverse(items);
                    break;
                case "start_date-asc":
                    items = _sortBy(items, ["start_date"]);
                    break;
                case "name-desc":
                    items = _sortBy(items, [(i) => i.name.toLowerCase()]);
                    items = _reverse(items);
                    break;
                case "name-asc":
                    items = _sortBy(items, [(i) => i.name.toLowerCase()]);
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination(items, perPage, page);

        return { items, total };
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return (
            <Pagination
                total={totalCount}
                perPage={perPage}
                page={page}
                doneLoaded={true}
                style={{ marginTop: "20px" }}
                onPageChange={(newPage) => this.setState({ page: newPage })}
            />
        );
    };

    renderTableActions = () => {
        const { regionsList, showCoreCourseOnly } = this.props;
        const { sortBy, filterBy, perPage, searchterms } = this.state;
        return (
            <TableBar
                filterBy={filterBy || ""}
                filterByPlaceholder="All Entities"
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={
                    showCoreCourseOnly
                        ? [
                              { value: "date-desc", label: "Recent Modified first" },
                              { value: "date-asc", label: "Oldest Modified first" },
                              { value: "name-asc", label: "Name ( A - Z)" },
                              { value: "name-desc", label: "Name ( Z - A )" },
                          ]
                        : [
                              { value: "date-desc", label: "Recent Modified first" },
                              { value: "date-asc", label: "Oldest Modified first" },
                              { value: "start_date-desc", label: "Schedule Date (Recent First)" },
                              { value: "start_date-asc", label: "Schedule Date (Oldest First)" },
                              { value: "name-asc", label: "Name ( A - Z)" },
                              { value: "name-desc", label: "Name ( Z - A )" },
                          ]
                }
                filterByOptions={getSelectOptions({ list: regionsList || [], keys: { value: "id", label: "label" } })}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }}
            />
        );
    };

    renderPricingCell = (item) => {
        const { selectables } = this.props;
        var currency_list = selectables && isArrayExists(selectables) ? _find(selectables, { id: "currency" }) : false,
            currency =
                currency_list && currency_list.options && isArrayExists(currency_list.options) && item.currency && !_isEmpty(item.currency)
                    ? _find(currency_list.options, { id: item.currency })
                    : false;
        return (
            <div>
                {item && item.pricing_category && !_isEmpty(item.pricing_category)
                    ? _map(item.pricing_category, (pricing_cat) => {
                          if (pricing_cat.id && pricing_cat.id === "281") {
                              return (
                                  <div key={pricing_cat.id}>
                                      {/* {( pricing_cat.condition && !_isEmpty( pricing_cat.condition ) ? pricing_cat.condition  + ': ' : '' )} */}
                                      {/* separate out pricing for course & schedule  */}
                                      {item.schedule_id && !_isEmpty(item.schedule_id) ? (
                                          pricing_cat.price && !_isEmpty(pricing_cat.price) ? (
                                              <strong style={{ fontWeight: "400" }}>
                                                  {(currency && currency.value) || ""}
                                                  {formatMoney(parseFloat(pricing_cat.price), 2, 3)}
                                              </strong>
                                          ) : (
                                              "---"
                                          )
                                      ) : pricing_cat.price && !_isEmpty(pricing_cat.price) && _toString(pricing_cat.price) !== "0" ? (
                                          <strong style={{ fontWeight: "400" }}>
                                              {(currency && currency.value) || ""}
                                              {formatMoney(parseFloat(pricing_cat.price), 2, 3)}
                                          </strong>
                                      ) : (
                                          "---"
                                      )}
                                  </div>
                              );
                          } // end - pricing_cat.id
                      })
                    : "---"}
            </div>
        );
    };

    renderScheduleDateCell = (item) => {
        var start_date = item.start_date ? getMomentTime(item.start_date, "DD-MM-YYYY") : false,
            end_date = item.end_date ? getMomentTime(item.end_date, "DD-MM-YYYY") : false;
        return start_date ? start_date + (end_date && end_date !== start_date ? " - " + end_date : "") : "---";
    };

    renderTable = (items) => {
        const { showCoreCourseOnly } = this.props;
        return (
            <Table
                items={items}
                showCheckbox={false}
                cells={
                    showCoreCourseOnly
                        ? [
                              { id: "name", label: "Product", render: (item) => item.name || "" },
                              { id: "pricing", label: "Regular Fee", render: (item) => this.renderPricingCell(item) },
                          ]
                        : [
                              { id: "name", label: "Product", render: (item) => item.name || "" },
                              {
                                  id: "start_date",
                                  label: "Start Date",
                                  render: (item) => (item.start_date ? getMomentTime(item.start_date, "DD-MM-YYYY") : "---"),
                              },
                              {
                                  id: "end_date",
                                  label: "End Date",
                                  render: (item) => (item.end_date ? getMomentTime(item.end_date, "DD-MM-YYYY") : "---"),
                              },
                              { id: "pricing", label: "Regular Fee", render: (item) => this.renderPricingCell(item) },
                              { id: "location", label: "Location", render: (item) => item.location || "--" },
                              { id: "city", label: "City", render: (item) => item.city || "---" },
                          ]
                }
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <InfoButton key="select" size="small" onClick={this.handleProductSelect.bind(this, item)}>
                            <i className="fa fa-edit"></i>Select
                        </InfoButton>
                    </ButtonGroup>
                )}
            />
        );
    };

    renderModal = () => {
        const { randNum } = this.state;
        const { items, total } = this.reorganizeData();
        return !randNum ? (
            <DotsLoader />
        ) : (
            <div>
                {this.renderTableActions()}
                {this.renderTable(items)}
                {this.renderPagination(total)}
            </div>
        );
    };

    render() {
        const { open } = this.props;
        return (
            <ModalView
                open={open}
                title="Select Product"
                noAction={true}
                actionLabel="Select"
                maxWidth="lg"
                cancelLabel="Close"
                onClose={this.handleClose}
                doAction={this.handleProductSelect}
                contents={open ? this.renderModal() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: (state.auth && state.auth.user) || null,
        productsOptions: (state.products && state.products.products_options) || null,
        regionsList: (state.maintenance && state.maintenance.regions) || null,
        randNum: (state.products && state.products.options_rand) || null,
    };
};

export default compose(connect(mapStateToProps))(ModalSelectProducts);
